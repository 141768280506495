// services/spotify.js

// Accediamo alle variabili d'ambiente di Spotify
const SPOTIFY_CLIENT_ID = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
const SPOTIFY_CLIENT_SECRET = process.env.REACT_APP_SPOTIFY_CLIENT_SECRET;

// Verifichiamo che le credenziali siano presenti
if (!SPOTIFY_CLIENT_ID || !SPOTIFY_CLIENT_SECRET) {
  console.error('Spotify credentials are missing in environment variables');
}

// Funzione per codificare le credenziali in base64 per l'autenticazione
const encodeCredentials = () => {
  return btoa(`${SPOTIFY_CLIENT_ID}:${SPOTIFY_CLIENT_SECRET}`);
};

// Implementiamo un sistema di cache per evitare troppe chiamate API
const cache = {
  artists: new Map(),
  topTracks: new Map(),
  duration: 3600000 // Cache duration: 1 hour in milliseconds
};

// Gestore dell'autenticazione Spotify
class SpotifyAuthManager {
  constructor() {
    this.accessToken = null;
    this.tokenExpirationTime = null;
  }

  isTokenValid() {
    return (
      this.accessToken && 
      this.tokenExpirationTime && 
      Date.now() < this.tokenExpirationTime - 10000
    );
  }

  async getAccessToken() {
    if (this.isTokenValid()) {
      return this.accessToken;
    }

    try {
      const params = new URLSearchParams();
      params.append('grant_type', 'client_credentials');

      const response = await fetch('https://accounts.spotify.com/api/token', {
        method: 'POST',
        headers: {
          'Authorization': `Basic ${encodeCredentials()}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: params
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Spotify API Error: ${errorData.error_description || response.statusText}`);
      }

      const data = await response.json();
      this.accessToken = data.access_token;
      this.tokenExpirationTime = Date.now() + (data.expires_in * 1000);
      
      return this.accessToken;
    } catch (error) {
      console.error('Spotify authentication error:', error);
      throw error;
    }
  }
}

// Creiamo un'istanza singola del gestore di autenticazione
const authManager = new SpotifyAuthManager();

// Classe principale per le interazioni con l'API di Spotify
class SpotifyService {
  static async makeRequest(url, retryCount = 0) {
    const MAX_RETRIES = 3;  // Prevent infinite retries

    try {
      const token = await authManager.getAccessToken();
      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      // Handle rate limiting with exponential backoff
      if (response.status === 429) {
        if (retryCount >= MAX_RETRIES) {
          throw new Error('Max retries reached. Rate limit exceeded.');
        }

        const retryAfter = parseInt(response.headers.get('Retry-After') || '1');
        const backoffTime = retryAfter * Math.pow(2, retryCount) * 1000;

        console.warn(`Rate limit hit. Retrying in ${backoffTime/1000} seconds. Attempt ${retryCount + 1}`);
        
        await new Promise(resolve => setTimeout(resolve, backoffTime));
        
        return this.makeRequest(url, retryCount + 1);
      }

      if (!response.ok) {
        throw new Error(`Spotify API Error: ${response.statusText}`);
      }

      return response.json();
    } catch (error) {
      console.error('Spotify API request failed:', error);
      throw error;
    }
  }

  static async searchArtist(artistName) {
    // Verifichiamo prima la cache
    const cachedData = cache.artists.get(artistName);
    if (cachedData && (Date.now() - cachedData.timestamp < cache.duration)) {
      return cachedData.data;
    }

    const encodedArtist = encodeURIComponent(artistName);
    const data = await this.makeRequest(
      `https://api.spotify.com/v1/search?q=${encodedArtist}&type=artist&limit=1`
    );

    const artist = data.artists.items[0];
    if (artist) {
      cache.artists.set(artistName, {
        data: artist,
        timestamp: Date.now()
      });
    }

    return artist;
  }

  static async getArtistTopTracks(artistId) {
    // Verifichiamo prima la cache
    const cacheKey = `topTracks-${artistId}`;
    const cachedData = cache.topTracks.get(cacheKey);
    if (cachedData && (Date.now() - cachedData.timestamp < cache.duration)) {
      return cachedData.data;
    }

    // Utilizziamo IT come mercato predefinito, ma si potrebbe permettere la personalizzazione
    const data = await this.makeRequest(
      `https://api.spotify.com/v1/artists/${artistId}/top-tracks?market=IT`
    );

    // Estraiamo e formatiamo solo i dati che ci servono
    const formattedTracks = data.tracks.map(track => ({
      id: track.id,
      name: track.name,
      albumName: track.album.name,
      albumImage: track.album.images[0]?.url,
      previewUrl: track.preview_url,
      spotifyUrl: track.external_urls.spotify,
      durationMs: track.duration_ms,
      popularity: track.popularity,
      explicit: track.explicit
    }));

    // Salviamo nella cache
    cache.topTracks.set(cacheKey, {
      data: formattedTracks,
      timestamp: Date.now()
    });

    return formattedTracks;
  }

  static async getArtistInfo(artistId) {
    return this.makeRequest(
      `https://api.spotify.com/v1/artists/${artistId}`
    );
  }

  static async getCompleteArtistData(artistName) {
    try {
      const artist = await this.searchArtist(artistName);
      if (!artist) {
        throw new Error(`Artist "${artistName}" not found`);
      }

      const [artistInfo, topTracks] = await Promise.all([
        this.getArtistInfo(artist.id),
        this.getArtistTopTracks(artist.id)
      ]);

      return {
        id: artist.id,
        name: artist.name,
        spotifyUrl: artist.external_urls.spotify,
        imageUrl: artist.images[0]?.url,
        genres: artistInfo.genres,
        monthlyListeners: artistInfo.followers.total,
        popularity: artistInfo.popularity,
        topTracks: topTracks,
        // Se desideri ancora accedere alla traccia principale separatamente
        topTrack: topTracks[0] ? {
          name: topTracks[0].name,
          previewUrl: topTracks[0].previewUrl,
          spotifyUrl: topTracks[0].spotifyUrl,
          albumImage: topTracks[0].albumImage
        } : null
      };
    } catch (error) {
      console.error('Error getting artist data:', error);
      throw error;
    }
  }
}

export default SpotifyService;