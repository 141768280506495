import React, {useState, useEffect} from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import Header from "./components/header/header";
import Home from './pages/Home/Home';
import About from './pages/About/About';
import './App.css';
import Festival from './pages/Festival/Festival';
import Footer from './components/footer/footer';
import Explore from './pages/Explore/Explore';
import { initGA, logPageView } from './utils/analytics';
import './i18n';
import LanguageSelector from './components/language-selector/language-selector';
import { HelmetProvider } from 'react-helmet-async';

function AnalyticsTracker() {
  const location = useLocation();

  useEffect(() => {
    if (location) {
      logPageView(location.pathname + location.search);
    }
  }, [location]);

  return null;
}

// Funzione per convertire lo slug in nome festival
const slugToFestivalName = (slug) => {
  return slug
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const App = () => {
  const [minimize, setMinimize] = useState(window.scrollY);
  const [currentFestivalName, setCurrentFestivalName] = useState('');
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    // Controlla se siamo in una pagina festival
    if (location.pathname.startsWith('/festival/')) {
      const festivalSlug = location.pathname.split('/festival/')[1];
      const formattedName = slugToFestivalName(festivalSlug);
      setCurrentFestivalName(formattedName);
    } else {
      setCurrentFestivalName('');
    }
  }, [location]);

  useEffect(() => {
    initGA('G-9MWTNEBT3X');

    const handleScroll = () => {
      if (window.scrollY < 400) {
        setMinimize(false);
      }
      if (window.scrollY > 400) {
        setMinimize(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [minimize]);

  return (
    <HelmetProvider>
    <div className="App">
      <AnalyticsTracker />
      <CookieConsent
        location="bottom"
        buttonText={t('cookieConsent.button')}
        cookieName="CookiesSoundTrip"
        style={{ background: "rgb(32, 39, 70)", fontSize: "0.8rem", padding: "0.5rem" }}
        buttonStyle={{ color: "rgb(32, 39, 70)", fontSize: "1.2rem", position: "left", borderRadius: "5px", background: "#FFA666", fontWeight: "600" }}
        expires={150}
      >
        {t('cookieConsent.message')}{" "}
        <a href="/privacy-policy" style={{ color: "#ffffff" }}>
          {t('cookieConsent.learnMore')}
        </a>
      </CookieConsent>
      <LanguageSelector />
      <Header minimize={minimize} festivalName={decodeURIComponent(currentFestivalName)?.normalize('NFC').toUpperCase()}/>
      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/festival" element={<Explore />} />
        <Route path="/festival/:route" element={<Festival />} />
      </Routes>
      <Footer />
    </div>
    </HelmetProvider>
  );
};

export default App;