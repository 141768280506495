import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import videoUrl1 from '../../assets/video-1.mp4';

const VideoBackground = ({ children, youtubeUrl, overlay = false, overlayOpacity = 0, height = 'auto' }) => {
  // Stati raggruppati per ridurre i re-render
  const [videoState, setVideoState] = useState({
    isMuted: true,
    isReady: false,
    isPreviewVisible: true,
    isPlaying: true
  });
  
  const [previewImage, setPreviewImage] = useState(null);
  const videos = useMemo(() => [videoUrl1], []);


  useEffect(() => {
    let readyTimer;
    const currentVideo = videoRef.current;
    
    const handleVideoCanPlay = () => {
      readyTimer = setTimeout(() => {
        setVideoState(prev => ({
          ...prev,
          isReady: true,
          isPreviewVisible: false
        }));
      }, 5000);
    };
  
    if (currentVideo) {
      currentVideo.addEventListener('canplay', handleVideoCanPlay);
    }
  
    return () => {
      if (currentVideo) {
        currentVideo.removeEventListener('canplay', handleVideoCanPlay);
      }
      clearTimeout(readyTimer);
    };
  }, []);
  
  // Refs
  const containerRef = useRef(null);
  const iframeRef = useRef(null);
  const videoRef = useRef(null);
  const isComponentMounted = useRef(true);

  // Estrazione dell'ID di YouTube memoizzata
  const videoId = useMemo(() => {
    if (!youtubeUrl) return null;
    const match = youtubeUrl.match(/(?:youtu\.be|youtube\.com(?:\/(?:[^/]+\/.+|(?:v|e(?:mbed)?))|.*[?&]v=)|youtu\.be)\/([^"&?/\s]{11})/);
    return match?.[1] || null;
  }, [youtubeUrl]);

  // Parametri YouTube ottimizzati
  const youtubeParams = useMemo(() => ({
    autoplay: '1',
    mute: '1',
    loop: '1',
    playlist: videoId,
    controls: '0',
    showinfo: '0',
    rel: '0',
    modestbranding: '1',
    iv_load_policy: '3',
    enablejsapi: '1',
    playsinline: '1',
    origin: window.location.origin,
    autohide: '1',
    disablekb: '1',
    fs: '0',
    vq: 'hd720', // Usare hd720 invece di hd1080 per prestazioni migliori
    version: '3'
  }), [videoId]);

  // Handler per i cambiamenti di stato
  const handleVideoStateChange = useCallback((newState) => {
    if (!isComponentMounted.current) return;
    setVideoState(prev => ({ ...prev, ...newState }));
  }, []);

  // Handler YouTube ottimizzato
  const handleYouTubeMessage = useCallback((event) => {
    if (event.origin !== 'https://www.youtube.com' || !isComponentMounted.current) return;

    try {
      const data = JSON.parse(event.data);
      if (data.event !== 'onStateChange') return;

      switch(data.info) {
        case 1: // Playing
          handleVideoStateChange({ isReady: true, isPreviewVisible: false });
          break;
        case 2: // Paused
          if (iframeRef.current && videoState.isPlaying) {
            iframeRef.current.contentWindow.postMessage(
              JSON.stringify({ event: 'command', func: 'playVideo', args: [] }), 
              '*'
            );
          }
          break;
        default:
          break;
      }
    } catch (error) {
      // Silent error handling
    }
  }, [handleVideoStateChange, videoState.isPlaying]);

  // Gestione del toggle audio ottimizzata
  const toggleMute = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    if (iframeRef.current) {
      const newMuteState = !videoState.isMuted;
      
      // Sequenza di comandi ottimizzata
      iframeRef.current.contentWindow.postMessage(
        JSON.stringify({
          event: 'command',
          func: 'playVideo',
          args: []
        }), 
        '*'
      );

      iframeRef.current.contentWindow.postMessage(
        JSON.stringify({
          event: 'command',
          func: newMuteState ? 'mute' : 'unMute',
          args: []
        }), 
        '*'
      );

      handleVideoStateChange({ isMuted: newMuteState });
    } else if (videoRef.current) {
      videoRef.current.muted = !videoState.isMuted;
      handleVideoStateChange({ isMuted: !videoState.isMuted });
    }
  }, [videoState.isMuted, handleVideoStateChange]);

  // Caricamento ottimizzato della thumbnail
  useEffect(() => {
    const loadThumbnail = async () => {
      if (!videoId || !isComponentMounted.current) return;

      try {
        const response = await fetch(`https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`);
        if (response.ok && isComponentMounted.current) {
          setPreviewImage(`https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`);
        }
      } catch {
        // Silent fallback
      }
    };

    loadThumbnail();
  }, [videoId]);

  // Gestione del ciclo di vita del componente
  // Mount/Unmount effect
useEffect(() => {
  // Store refs in variables
  const videoElement = videoRef.current;
  const iframeElement = iframeRef.current;
  
  isComponentMounted.current = true;

  return () => {
    isComponentMounted.current = false;
    
    // Use stored refs in cleanup
    if (iframeElement) {
      iframeElement.src = '';
    }
    if (videoElement) {
      videoElement.pause();
      videoElement.removeAttribute('src');
      videoElement.load();
    }
  };
}, []);

  // Listener YouTube
  useEffect(() => {
    window.addEventListener('message', handleYouTubeMessage);
    return () => window.removeEventListener('message', handleYouTubeMessage);
  }, [handleYouTubeMessage]);

  // Gestione visibilità documento
  useEffect(() => {
    const currentIframe = iframeRef.current;
    const handleVisibilityChange = () => {
      if (!isComponentMounted.current) return;
      
      if (document.visibilityState === 'visible' && currentIframe) {
        handleVideoStateChange({ isPlaying: true });
        currentIframe.contentWindow.postMessage(
          JSON.stringify({
            event: 'command',
            func: 'playVideo',
            args: []
          }),
          '*'
        );
      }
    };
  
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleVideoStateChange]);

  // Stili ottimizzati e memoizzati
  const styles = useMemo(() => ({
    container: {
      position: 'relative',
      width: '100%',
      height,
      aspectRatio: '16 / 9',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#000',
      borderRadius: '10px',
      willChange: 'transform'
    },
    videoCommon: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      transition: 'opacity 0.5s ease-in-out',
      willChange: 'transform, opacity'
    },
    preview: {
      zIndex: 1,
      opacity: videoState.isPreviewVisible ? 1 : 0,
      pointerEvents: 'none',
    },
    youtubeWrapper: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      zIndex: 0,
    },
    iframe: {
      transform: 'translate(-50%, -50%)',
      transformOrigin: 'center center',
      pointerEvents: 'none',
      border: 'none',
      opacity: 1,
    },
    overlay: overlay ? {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: `rgba(0, 0, 0, ${overlayOpacity})`,
      zIndex: 2
    } : null,
    content: {
      position: 'relative',
      zIndex: 3,
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    audioButton: {
      position: 'absolute',
      bottom: '10px',
      right: '10px',
      zIndex: 4,
      padding: '8px 12px',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      border: '1px solid rgba(255, 255, 255, 0.3)',
      borderRadius: '4px',
      color: 'white',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      gap: '6px',
      fontSize: '14px',
      fontWeight: '500',
      transition: 'all 0.3s ease',
      pointerEvents: 'auto',
      willChange: 'transform',
      backdropFilter: 'blur(4px)'
    }
  }), [height, overlay, overlayOpacity, videoState.isPreviewVisible]);

  // Rendering video ottimizzato
  const renderVideo = useMemo(() => {
    if (youtubeUrl && videoId) {
      return (
        <>
          {videoState.isPreviewVisible && previewImage && (
            <img 
              src={previewImage} 
              alt=""
              style={{ ...styles.videoCommon, ...styles.preview }}
              loading="lazy"
              decoding="async"
            />
          )}
          <div style={styles.youtubeWrapper}>
            <iframe
              ref={iframeRef}
              style={{
                ...styles.videoCommon,
                ...styles.iframe,
                opacity: videoState.isReady && videoState.isPlaying ? 1 : 0,
              }}
              src={`https://www.youtube.com/embed/${videoId}?${new URLSearchParams(youtubeParams).toString()}`}
              allow="autoplay; encrypted-media"
              title="Festival video"
              loading="lazy"
            />
          </div>
        </>
      );
    }

    return (
      <video
        ref={videoRef}
        autoPlay={videoState.isPlaying}
        muted
        loop
        playsInline
        style={{
          ...styles.videoCommon,
          opacity: videoState.isPlaying ? 1 : 0
        }}
        src={videos[0]}
      >
        <track kind="captions" />
      </video>
    );
  }, [youtubeUrl, videoId, videoState, previewImage, styles, videos, youtubeParams]);

  return (
    <div ref={containerRef} style={styles.container}>
      {renderVideo}
      {styles.overlay && <div style={styles.overlay} />}
      <div style={styles.content}>{children}</div>
      
      {youtubeUrl && videoState.isReady && !videoState.isPreviewVisible && (
        <button 
          onClick={toggleMute}
          style={styles.audioButton}
          aria-label={videoState.isMuted ? "Attiva audio" : "Disattiva audio"}
        >
          <span>{videoState.isMuted ? "🔇" : "🔊"}</span>
          <span>{videoState.isMuted ? "OFF" : "ON"}</span>
        </button>
      )}
    </div>
  );
};

export default React.memo(VideoBackground);