import { useState, useEffect, useRef } from 'react';

const ease = (t, b, c, d) => {
  t /= d / 2;
  if (t < 1) return c / 2 * t * t + b;
  t--;
  return -c / 2 * (t * (t - 2) - 1) + b;
};

// Funzione per ottenere la posizione target considerando il centramento verticale
const getTargetPosition = (element, offset = 0) => {
  const rect = element.getBoundingClientRect();
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const windowHeight = window.innerHeight;
  const elementHeight = rect.height;

  // Calcola la posizione per centrare l'elemento
  const centerPosition = scrollTop + rect.top - (windowHeight / 2) + (elementHeight / 2);
  
  return Math.max(0, centerPosition - offset);
};

export const smoothScroll = (target, offset = 0, duration = 800) => {
  return new Promise((resolve) => {
    let isInterrupted = false;
    
    // Se target è una stringa (id dell'elemento), trova l'elemento
    const targetElement = typeof target === 'string' 
      ? document.querySelector(target)
      : target;

    if (!targetElement && typeof target !== 'number') {
      console.warn('Target element not found');
      resolve(false);
      return;
    }

    // Calcola la posizione finale dello scroll
    const targetPosition = typeof target === 'number' 
      ? target 
      : getTargetPosition(targetElement, offset);

    const startPosition = window.pageYOffset || document.documentElement.scrollTop;
    const distance = targetPosition - startPosition;
    
    let startTime = null;
    let lastScrollPosition = startPosition;
    let animationFrameId = null;

    // Handler per lo scroll dell'utente
    const handleUserScroll = () => {
      const currentPosition = window.pageYOffset || document.documentElement.scrollTop;
      if (Math.abs(currentPosition - lastScrollPosition) > 5) {
        isInterrupted = true;
        cleanup();
        resolve(false);
      }
      lastScrollPosition = currentPosition;
    };

    // Cleanup function
    const cleanup = () => {
      window.removeEventListener('wheel', handleUserScroll);
      window.removeEventListener('touchmove', handleUserScroll);
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };

    // Aggiungi event listeners
    window.addEventListener('wheel', handleUserScroll, { passive: true });
    window.addEventListener('touchmove', handleUserScroll, { passive: true });

    function animation(currentTime) {
      if (isInterrupted) return;
      
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      
      if (timeElapsed >= duration) {
        window.scrollTo({
          top: targetPosition,
          behavior: 'auto'
        });
        cleanup();
        resolve(true);
        return;
      }

      const nextPosition = ease(timeElapsed, startPosition, distance, duration);
      window.scrollTo(0, nextPosition);
      lastScrollPosition = nextPosition;
      
      animationFrameId = requestAnimationFrame(animation);
    }

    animationFrameId = requestAnimationFrame(animation);
  });
};

// Hook personalizzato per React
export const useScrollToElement = () => {
  const isScrollingRef = useRef(false);

  const scrollTo = async (elementId, options = {}) => {
    const { 
      offset = 0, 
      duration = 800
    } = options;

    if (isScrollingRef.current) return;
    
    isScrollingRef.current = true;
    try {
      await smoothScroll(`#${elementId}`, offset, duration);
    } finally {
      isScrollingRef.current = false;
    }
  };

  return scrollTo;
};

// Componente ScrollToTop
export const ScrollToTop = ({ showBelow = 300 }) => {
  const [show, setShow] = useState(false);
  const scrollToTopRef = useRef(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setShow(scrollTop > showBelow);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [showBelow]);

  const handleClick = async () => {
    if (scrollToTopRef.current) return;
    
    scrollToTopRef.current = true;
    try {
      await smoothScroll(0);
    } finally {
      scrollToTopRef.current = false;
    }
  };

  return show ? (
    <button 
      onClick={handleClick}
      className="fixed bottom-8 right-8 p-2 rounded-full bg-blue-500 text-white shadow-lg hover:bg-blue-600 transition-all"
      aria-label="Torna su"
    >
      ↑
    </button>
  ) : null;
};