import React from 'react';
import { useState, useEffect } from 'react';
import './wave-text.style.css';

const WaveText = ({ 
  text, 
  color = "white",
  waveHeight = 20,
  wavelength = 2, // Controlla quante "onde" ci sono nel testo
  speed = 1 // Velocità dell'animazione
}) => {
  const [letters, setLetters] = useState([]);

  useEffect(() => {
    // Calcola il ritardo per ogni lettera per creare un'onda fluida
    const lettersArray = text.split('').map((letter, index) => ({
      letter,
      // Il ritardo è calcolato in modo che le lettere si muovano come un'onda sinusoidale
      delay: (index / text.length) * wavelength * Math.PI * 2
    }));
    setLetters(lettersArray);
  }, [text, wavelength]);

  return (
    <div className="flex justify-center items-center min-h-[200px] bg-gray-50 p-8 rounded-lg">
      <div className="text-4xl font-bold tracking-wider">
        {letters.map(({ letter, delay }, index) => (
          <span
            key={index}
            className="inline-block letter"
            style={{
              color: color,
              animation: `waveFloat ${3/speed}s infinite ease-in-out`,
              animationDelay: `${-delay}s`,
            }}
          >
            {letter === ' ' ? '\u00A0' : letter}
          </span>
        ))}
      </div>
      <style jsx>{`
        @keyframes waveFloat {
          0%, 100% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-${waveHeight}px);
          }
        }

        span {
          display: inline-block;
          backface-visibility: hidden;
          will-change: transform;
        }

        @media (prefers-reduced-motion: reduce) {
          span {
            animation: none;
            transform: translateY(0);
          }
        }
      `}</style>
    </div>
  );
};

export default WaveText;