import React from "react";
import { format, isValid, parse, differenceInCalendarDays, } from "date-fns";
import { it } from "date-fns/locale";
import "./booking.style.css";

const BookingHotels = ({ city, link, checkIn, checkOut }) => {
  const formatDisplayDate = (dateStr1, dateStr2) => {
    if (!dateStr1 || !dateStr2) return "";
    
    const date1 = parse(dateStr1, "yyyy-MM-dd", new Date());
    const date2 = parse(dateStr2, "yyyy-MM-dd", new Date());
    
    if (!isValid(date1) || !isValid(date2)) return "";
    
    const month1 = format(date1, "MMMM", { locale: it });
    const month2 = format(date2, "MMMM", { locale: it });
    
    const dateString1 = month1 !== month2 
      ? format(date1, "d MMMM", { locale: it }) 
      : format(date1, "d", { locale: it });
    const dateString2 = format(date2, "d MMMM", { locale: it });
    
    return `${dateString1} - ${dateString2}`;
  };

  const getDateDifference = (startDate, endDate) => {
    if (!startDate || !endDate) return 0;
    
    const parsedStartDate = parse(startDate, "yyyy-MM-dd", new Date());
    const parsedEndDate = parse(endDate, "yyyy-MM-dd", new Date());
    
    if (!isValid(parsedStartDate) || !isValid(parsedEndDate)) return 0;
    
    return differenceInCalendarDays(parsedEndDate, parsedStartDate);
  };

  const generateBookingUrl = () => {
    if (!city || !checkIn || !checkOut) return "";
    
    const baseUrl = "https://www.booking.com/searchresults.it.html";
    const params = new URLSearchParams({
      ss: city,
      lang: "it",
      checkin: checkIn,  // Non modifichiamo più la data
      checkout: checkOut,
      sb: "1",
      src_elem: "sb",
      src: "index",
      group_adults: "1",
      no_rooms: "1",
      group_children: "0",
      aid: "2311236"
    });

    return `${baseUrl}?${params.toString()}`;
  };

  const bookingUrl = link || generateBookingUrl();
  
  
  if (!bookingUrl) return null;

  const nights = getDateDifference(checkIn, checkOut);

  return (
    <div className="hotel-info">
      <div className="hotel-info-wrapper">
        <span className="font-medium">
          <span>
            Hotel a <br /> {city} <br />
            {formatDisplayDate(checkIn, checkOut)}<br/>
          </span>
          <span>{nights} {nights > 1 ? 'notti' : 'notte'}</span>
        </span>
        <a
          className="train-search"
          href={bookingUrl}
          target="_blank"
          rel="sponsored noopener noreferrer"
        >
          <span>Prenota <small className="text-white">su Booking</small></span>
        </a>
      </div>
    </div>
  );
};

export default BookingHotels;