import React from "react";
import "./train-search.style.css";
import trainIcon from "../../assets/train-ticket-icon.png";

const generateTripUrl = (departure, arrival, date, returnDate) => {
  // Safely extract station code and name
  const getStationInfo = (city) => {
    if (!city) return { code: '', name: '' };
    
    // Prefer station code if available
    const stations = city.stations || [];
    const primaryStation = stations.length > 0 
      ? stations[0] 
      : { code: '', name: city.name };
    
    return {
      code: primaryStation.code || '',
      name: primaryStation.name || city.name || ''
    };
  };

  // Extract station information
  const departureStation = getStationInfo(departure);
  const arrivalStation = getStationInfo(arrival);

  // Construct URL parameters
  const params = new URLSearchParams({
    locale: 'it_IT',
    curr: 'EUR',
    departurecitycode: departureStation.code,
    arrivalcitycode: arrivalStation.code,
    departurecity: departureStation.name,
    arrivalcity: arrivalStation.name,
    departdate: date,
    departhouript: "21", // Ora specificata nell'esempio
    departminuteipt: "00",
    returndate: returnDate || '',
    returnhouript: "00",
    returnminuteipt: "00",
    hidadultnum: "1",
    hidinfantnum: "0",
    hidoldnum: "0",
    useUserPassenger: "1",
    biztype: "IT"
  });

  // Remove empty parameters
  for (const [key, value] of params.entries()) {
    if (value === '') params.delete(key);
  }

  // Genera URL affiliato
  const baseUrl = `https://www.trip.com/trains/list?${params.toString()}`;
  
  return `https://tp.media/r?marker=581462&trs=364963&p=8626&u=${encodeURIComponent(baseUrl)}&campaign_id=121`;
};

const TrainSearch = ({ departure, arrival, date, returnDate }) => {
  return (
    <div className="train-info" key={departure.stationName}>
      <img src={trainIcon} alt="train-icon" className="train-icon" />
      <div className="train-info-wrapper">
          <span className="train-station-departure">
            {departure.stationName}   
          </span>
          <span className="train-station-arrival">
          {arrival.stationName}
          {arrival?.distanceFromFestival &&  <span className="distance-airport"><br/>* {arrival?.distanceFromFestival} da {arrival?.name}</span>}
          </span>
      </div>
      <div className="train-button-search-wrapper">
      <a
        className="train-search"
        href={generateTripUrl(departure, arrival, date, returnDate)}
        target="_blank"
        rel="sponsored noopener noreferrer"
      >
        <span>Prenota<small style={{ color: "white" }}>su Trip</small></span>
      </a>
      </div>    
    </div>
  );
};

export default TrainSearch;