const CircularLoader = () => {
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
      }}>
        <svg 
          width="50" 
          height="50" 
          viewBox="0 0 50 50"
          style={{
            animation: 'rotate 1s linear infinite'
          }}
        >
          <style>
            {`
              @keyframes rotate {
                100% {
                  transform: rotate(360deg);
                }
              }
            `}
          </style>
          <circle
            cx="25"
            cy="25"
            r="20"
            stroke="rgb(32, 39, 70)"
            strokeWidth="4"
            fill="none"
            strokeLinecap="round"
            style={{ opacity: 0 }}
          />
          <circle
            cx="25"
            cy="25"
            r="20"
            stroke="rgb(32, 39, 70)"
            strokeWidth="4"
            fill="none"
            strokeLinecap="round"
            strokeDasharray="80"
            strokeDashoffset="60"
            style={{
              transformOrigin: 'center',
              transform: 'rotate(-90deg)'
            }}
          />
        </svg>
      </div>
    );
  };
  
  export default CircularLoader;