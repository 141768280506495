import React from 'react';

const Loader = () => {
  const points = Array.from({ length: 20 }, (_, i) => i);
  
  const generateWaveKeyframes = (index) => {
    const keyframes = [];
    const steps = 20;
    
    for (let i = 0; i <= steps; i++) {
      const progress = (i / steps) * 100;
      const offset = Math.sin((progress / 100) * Math.PI * 2 + (index / points.length) * Math.PI * 2) * 15;
      keyframes.push(`${progress}% { transform: translateY(${offset}px); }`);
    }
    
    return keyframes.join('\n');
  };

  return (
    <div className="oscilloscope-container">
      <div className="oscilloscope">
        <div className="grid-lines horizontal">
          <div className="grid-line"></div>
          <div className="grid-line"></div>
          <div className="grid-line"></div>
        </div>
        
        <div className="grid-lines vertical">
          {Array.from({ length: 6 }, (_, i) => (
            <div key={i} className="grid-line"></div>
          ))}
        </div>

        <div className="points-container">
          {points.map((index) => (
            <div
              key={index}
              className="point"
              style={{
                left: `${(index / points.length) * 100}%`,
                animation: `wave${index} 3s infinite linear`
              }}
            />
          ))}
        </div>

        <div className="loading-text">Loading</div>
      </div>

      <style>{`
        .oscilloscope-container {
          width: 200px;
          height: 130px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px;
        }

        .oscilloscope {
          width: 100%;
          height: 100px;
          background-color: #111827;
          border-radius: 4px;
          padding: 8px;
          overflow: hidden;
          position: relative;
          box-shadow: 0 0 5px rgba(0, 255, 0, 0.1);
          font-family: 'MusicaPro', sans-serif;
        }

        .grid-lines {
          position: absolute;
          inset: 0;
        }

        .grid-lines.horizontal .grid-line {
          position: absolute;
          width: 100%;
          height: 1px;
          background-color: rgba(55, 65, 81, 0.7);
        }

        .grid-lines.horizontal .grid-line:nth-child(1) { top: 25%; }
        .grid-lines.horizontal .grid-line:nth-child(2) { top: 50%; }
        .grid-lines.horizontal .grid-line:nth-child(3) { top: 75%; }

        .grid-lines.vertical {
          display: flex;
          justify-content: space-between;
        }

        .grid-lines.vertical .grid-line {
          width: 1px;
          height: 100%;
          background-color: rgba(55, 65, 81, 0.7);
        }

        .points-container {
          position: relative;
          height: 100%;
          display: flex;
          align-items: center;
        }

        .point {
          position: absolute;
          width: 3px;
          height: 3px;
          background-color: #4ade80;
          border-radius: 50%;
          box-shadow: 0 0 3px #4ade80;
        }

        .loading-text {
          position: absolute;
          bottom: 4px;
          left: 50%;
          transform: translateX(-50%);
          font-family: monospace;
          font-size: 8px;
          color: #4ade80;
          text-shadow: 0 0 2px #4ade80;
          letter-spacing: 1px;
          animation: pulse 1.5s infinite;
        }

        @keyframes pulse {
          0%, 100% { opacity: 0.5; }
          50% { opacity: 1; }
        }

        ${points.map((index) => `
          @keyframes wave${index} {
            ${generateWaveKeyframes(index)}
          }
        `).join('\n')}

        .oscilloscope::before {
          content: '';
          position: absolute;
          inset: 0;
          background: radial-gradient(
            circle at center,
            rgba(74, 222, 128, 0.1) 0%,
            transparent 70%
          );
          pointer-events: none;
        }
      `}</style>
    </div>
  );
};

export default Loader;