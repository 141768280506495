import React, { useState, useEffect } from 'react';
import './services.style.css';

const ServiceCard = ({ service }) => {
  const [iconSrc, setIconSrc] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const loadIcon = async () => {
      try {
        setIsLoading(true);
        const iconName = service.name.toLowerCase().replace(/\s+/g, '-');
        const icon = await import(`../../assets/${iconName}.png`);
        setIconSrc(icon.default);
        setError(false);
      } catch (err) {
        console.warn(`Couldn't load icon for ${service.name}, using default`);
        try {
          const defaultIcon = await import('../../assets/glamping.png');
          setIconSrc(defaultIcon.default);
        } catch {
          setError(true);
        }
      } finally {
        setIsLoading(false);
      }
    };

    loadIcon();
  }, [service.name]);

  return (
    <div className="service-card">
      <div className="icon-container">
        {isLoading ? (
          <div className="loading-icon" />
        ) : error ? (
          <div className="error-icon" />
        ) : (
          <img className="service-icon" src={iconSrc} alt={service.name} />
        )}
      </div>
      
      {service.link ? (
        <a 
          href={service.link}
          target="_blank"
          rel="noopener noreferrer"
          className="service-name"
        >
          {service.name}
        </a>
      ) : (
        <span className="service-name">
          {service.name}
        </span>
      )}
    </div>
  );
};

const FestivalServices = ({ services }) => {
  if (!services || services.length === 0) return null;
   
    
  return (
    <div className="services-container">
     
      <div className="services-grid">
        {services.map((service, index) => (
          <ServiceCard key={index} service={service} />
        ))}
      </div>
    </div>
  );
};

export default FestivalServices;