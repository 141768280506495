import React from "react";
import "./festival-card.style.css";
import background from "../../assets/festival-card.jpg";
import locationIcon from "../../assets/location-icon.svg";
import dateIcon from "../../assets/calendar-icon.svg";
import microphoneIcon from "../../assets/lineup-icon.svg";
import { format, parse } from "date-fns";
import { it } from "date-fns/locale";
import Flags from '../flags/flags';

// Mappa dei nomi dei paesi ai componenti bandiera
const countryToFlag = {
  'Italy': Flags.ItalyFlag,
  'United Kingdom': Flags.UKFlag,
  'Germany': Flags.GermanyFlag,
  'Spain': Flags.SpainFlag,
  'France': Flags.FranceFlag,
  'Netherlands': Flags.NetherlandsFlag,
  'Portugal': Flags.PortugalFlag,
  'Switzerland': Flags.SwitzerlandFlag,
  'Poland': Flags.PolandFlag,
};


const FestivalCard = ({ festival, mini }) => {
  const cardStyle = {
    background: "#202746",
    backgroundImage: `url(${festival?.card ? festival.card : background}`, // Add a blur and brightness effect to the background image
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    color: "#fff", // Ensure text is readable on the background image
    transition: "all 0.5s",
    textDecoration: "none", // Remove link decoration
  };
  const parsedStartDate = parse(festival?.startDate, "dd/MM/yyyy", new Date());
  const formatteStartdDate = format(parsedStartDate, "d MMMM yyyy", {
    locale: it,
  });
  const parsedEndDate = parse(festival?.endDate, "dd/MM/yyyy", new Date());
  const formatteEnddDate = format(parsedEndDate, "d MMMM yyyy", {
    locale: it,
  });
  const formattedName = festival?.name.replace(/\s+/g, "-").toLowerCase();
  const FlagComponent = countryToFlag[festival?.country];
  return (
    <div style={cardStyle} className={`festival-card ${mini ? 'card-mini' : ''}`}>
      <a
        href={`/festival/${formattedName}`}
        rel="noopener noreferrer"
        className={`festival-card-link ${mini ? 'card-link-mini' : ''}`}
      >
        <div className="festival-card-info-container">
        <div className={`festival-card-name-container${mini ? '-mini' : ''}`}>
        {FlagComponent && mini && <div className="flag-icon"><FlagComponent /><span className="festival-city">{festival?.city}</span></div>}
      <h3 className={`card-name${mini ? '-mini' : ''}`}>
        {festival?.name}
      </h3>
    </div>
          {!mini && (
            <>
              <p className="card-date">
                <img src={locationIcon} loading="lazy" className="icon" alt="icon"></img>
                {`${festival?.location?.join(", ")}, ${festival?.city}`}
              </p>
              <p className="card-date">
                <img src={dateIcon} loading="lazy" className="icon" alt="icon"></img>
                {`Dal ${formatteStartdDate}`} {`al ${formatteEnddDate}`}
              </p>
              <p className="card-date">
                <img src={microphoneIcon} loading="lazy" className="icon" alt="icon"></img>
                <span>
                  {festival?.lineup[0] !== "TBA"
                    ? `${festival?.lineup?.slice(0,6).join(", ")} e molti altri...`
                    : "TBA"}
                </span>
              </p>
            </>
          )}
          
        </div>
      </a>
    </div>
  );
};

export default FestivalCard;
