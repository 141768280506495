import React, { useState, useRef, useEffect } from "react";
import citiesMap from "../../assets/utils/cities.json";
import "./travel-search.style.css";
import TrainSearch from "../trains-search/train-search";
import { addDays, format, parse } from "date-fns";
import { it } from "date-fns/locale";
import flightIcon from "../../assets/air-ticket-icon.png";
import ticketIcon from "../../assets/ticket-icon.png";
import ExpediaHotels from "../expedia-url/expedia";
import BookingHotels from "../booking/booking-hotels";
import OmioTravel from "../omio-travel/omioTravel";
import Flags from "../flags/flags";
import Loader from "../loader/loader";
import { useScrollToElement } from "../../assets/utils/scroll";
import LineupComponent from "../artist-card/artist-card";

const trackGAEvent = (
  action,
  category = "Button Click",
  additionalData = {}
) => {
  if (typeof window !== "undefined" && window.gtag) {
    window.gtag("event", action, {
      event_category: category,
      ...additionalData,
    });
  }
};

const cities = Object.entries(citiesMap.countries).flatMap(
  ([countryCode, country]) =>
    Object.values(country.cities).map((city) => ({
      name: city.name,
      region: city.region,
      airports: city.airports,
      stations: city.stations,
      country: city?.country,
    }))
);

const trackAffiliateClick = (category, label, additionalData = {}) => {
  if (typeof window !== "undefined" && window.gtag) {
    window.gtag("event", "affiliate_click", {
      event_category: category,
      event_label: label,
      ...additionalData,
    });
  }
};

const apiKey = "JoMFB5bnQQC7wN3TsM8692oo8k3dJ2WH";
const apiSecret = "2MHGztQgs3aHVti2";

const isBeforeStartDate = (saleStartDate) => {
  console.log(saleStartDate, new Date() < new Date(saleStartDate));

  if (!saleStartDate) return false;
  return new Date() < new Date(saleStartDate);
};

const TravelSearch = ({ festival }) => {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [token, setToken] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);
  const inputRef = useRef();
  const [selectedCity, setSelectedCity] = useState(null);
  const [error, setError] = useState(null);
  const [flightOffers, setFlightOffers] = useState([]);
  const [selectedNation, setSelectedNation] = useState(null);
  const festivalCity = cities.find((city) => city?.name === festival?.city);
  const [selectedTicket, setSelectedTicket] = useState();
  const [selectedDate, setSelectedDate] = useState(false);
  const tickets = festival?.tickets;
  const scrollToElement = useScrollToElement();

  const normalizeString = (str) => {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };

  const handleTicket = (ticket, index) => {
    setSelectedTicket(tickets[index]);
    setSelectedDate(false); // Reset selectedDate quando si seleziona un nuovo ticket
    trackGAEvent("select_ticket", "Ticket Selection", {
      ticket_type: ticket?.type,
      ticket_price: ticket?.minPrice,
      ticket_vendor: ticket?.vendor,
    });
    if (selectedCity) fetchFlightOffers(selectedCity);
  };

const generateTripFlightUrl = (date, returnDate) => {
  // Generates a unique identifier using a more standard approach
  const generateUniqueId = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).toString().replace(/[018]/g, c => {
      const randomValue = crypto.getRandomValues(new Uint8Array(1))[0];
      return (c ^ ((randomValue) & (15 >> (c / 4)))).toString(16);
    });
  };

  // Safely extract airport code
  const getAirportCode = (cityOrAirports) => {
    if (!cityOrAirports) return '';
    
    // If it's an array of airports
    if (Array.isArray(cityOrAirports)) {
      // Prefer IATA code if available, otherwise use first 3 letters of city name
      return cityOrAirports.length > 0 
        ? (cityOrAirports[0].iata_code || cityOrAirports[0].name?.toLowerCase().substring(0, 3))
        : '';
    }
    
    // If it's a city object
    if (cityOrAirports.airports && cityOrAirports.airports.length > 0) {
      return cityOrAirports.airports[0].iata_code 
        || cityOrAirports.name.toLowerCase().substring(0, 3);
    }
    
    return '';
  };

  // Hardcoded affiliate parameters (consider making these configurable)
  const AFFILIATE_CONFIG = {
    sid: '2209817',
    allianceid: '1094387'
  };

  // Safely get first airport code
  const departureCode = getAirportCode(selectedCity);
  const arrivalCode = getAirportCode(festivalCity);

  // Generate unique identifier
  const uniqueId = generateUniqueId();

  // Construct URL parameters
  const params = new URLSearchParams({
    sid: AFFILIATE_CONFIG.sid,
    locale: 'it_IT',
    aairport: arrivalCode,
    acitycode: arrivalCode,
    adate: returnDate,
    adult: "1",
    allianceid: AFFILIATE_CONFIG.allianceid,
    child: "0",
    classgroupsearch: "true",
    classtype: "0",
    dairport: departureCode,
    dcitycode: departureCode,
    ddate: date,
    infant: "0",
    ouid: uniqueId,
    stoptype: "0",
    triptype: "1"
  });

  const baseFlightUrl = `https://www.trip.com/m/flights/xflightfirst/?${params.toString()}`;

  // Genera URL affiliato
  return `https://tp.media/r?marker=581462&trs=364963&p=8626&u=${encodeURIComponent(baseFlightUrl)}&campaign_id=121`;

};

  async function getAccessToken() {
    const response = await fetch(
      "https://api.amadeus.com/v1/security/oauth2/token",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          grant_type: "client_credentials",
          client_id: apiKey,
          client_secret: apiSecret,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }

    const data = await response.json();
    return data.access_token;
  }

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = await getAccessToken();
        setToken(token);
        console.log("[Amadeus] Token acquired");
      } catch (error) {
        console.error("Error fetching access token:", error);
      }
    };

    fetchToken();
  }, []);

  const fetchFlightOffers = async (city) => {
    if (!token) return;
    setLoading(true);

    try {
      const response = await fetch(
        `https://api.amadeus.com/v2/shopping/flight-offers?originLocationCode=${
          city?.airports[0]?.iata_code
        }&destinationLocationCode=${
          festivalCity?.country === city?.country
            ? festivalCity?.airports[0]?.iata_code
            : festivalCity?.airports[1]
            ? festivalCity?.airports[1]?.iata_code
            : festivalCity?.airports[0]?.iata_code
        }&departureDate=${format(
          parse(selectedTicket?.startDate, "dd/MM/yyyy", new Date()),
          "yyyy-MM-dd"
        )}&returnDate=${format(
          addDays(parse(selectedTicket?.endDate, "dd/MM/yyyy", new Date()), 1),
          "yyyy-MM-dd"
        )}&adults=1&max=250`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        setError(response);
        setFlightOffers([]);
        setLoading(false);
        console.log(error);
        throw new Error("Network response was not ok " + response.statusText);
      }

      const data = await response.json();
      setFlightOffers(data);
      setLoading(false);
      
    } catch (error) {
      console.error("Error fetching flight offers:", error);
    }
  };

  useEffect(() => {
    const handleQuery = (query) => {
      if (!selectedNation) return;

      const normalizedQuery = normalizeString(query || "");

      const results = Object.values(
        citiesMap.countries[selectedNation].cities
      ).filter((city) => normalizeString(city.name).includes(normalizedQuery));

      query.length > 1 ? setFilteredCities(results) : setFilteredCities([]);
      selectedCity?.name !== query && setSelectedCity(null);
      setFlightOffers([]);
    };

    handleQuery(query);
  }, [query, selectedCity, selectedNation]);

  const formatStartDate = (date) => {
    const parsedStartDate = parse(date, "dd/MM/yyyy", new Date());
    const formattedStartdDate = format(parsedStartDate, "d MMMM yyyy", {
      locale: it,
    });
    return formattedStartdDate;
  };

  const formatEndDate = (date) => {
    const parsedEndDate = parse(date, "dd/MM/yyyy", new Date());
    const formattedEnddDate = format(parsedEndDate, "d MMMM yyyy", {
      locale: it,
    });
    return formattedEnddDate;
  };

  
  return (
    <>
      {tickets && <span className="divider">Seleziona la data</span>}

      {tickets && tickets.length > 0 && (
        <div className="ticket-results" id="tickets" >
          {tickets
            .filter(
              (ticket) =>
                !selectedDate || ticket.minPrice === selectedTicket?.minPrice
            )
            .map((ticket, index) => (
              <div
              id={`targetElement-${index}`}
                className={`ticket ${
                  ticket.minPrice === selectedTicket?.minPrice ? "selected" : ""
                } ${
                  index === 0 && festival?.tickets?.length === 1
                    ? "max-width"
                    : ""
                }
                ${
                  index === 0 && festival?.tickets[0].type === "bundle"
                    ? "max-width"
                    : ""
                }
                `}
                key={index}
                onClick={() => {
                  if (selectedTicket?.minPrice === ticket?.minPrice) return;
                  setSelectedTicket(ticket);
                  setSelectedDate(false);
                  handleTicket(ticket, index);
                  setFlightOffers([]);
                  setSelectedCity(null);
                  scrollToElement(`targetElement-${index}`, 80)
                }}
              >
                <span className="ticket-date">
                  {ticket?.startDate !== ticket?.endDate
                    ? ` Dal ${formatStartDate(
                        ticket?.startDate
                      )} al ${formatEndDate(ticket?.endDate)}`
                    : `${formatStartDate(ticket?.startDate)}`}
                </span>
                <div className="lineup">
                  {selectedTicket?.minPrice === ticket?.minPrice ? (
                    <LineupComponent lineup={selectedTicket?.lineup} />
                  ) : (
                    <div className="artist">{ticket.lineup[0]}</div>
                  )}
                </div>
                {ticket?.minPrice === selectedTicket?.minPrice && (
                  <div className="ticket-actions" >
                    {selectedDate && (
                      <a
                        className="ticket-link"
                        href={ticket?.ticketLink}
                        target="_blank"
                        rel="sponsored noopener noreferrer"
                        onClick={() =>
                          trackGAEvent("buy_ticket", "Purchase", {
                            ticket_vendor: ticket.vendor,
                            ticket_price: ticket.minPrice,
                            festival_name: festival.name,
                          })
                        }
                      >
                        <img
                          src={ticketIcon}
                          alt="ticket-icon"
                          className="ticket-icon"
                        />
                        <span className="buy-ticket">
                          <span style={{ color: "#FFA666" }}>Acquista</span> su{" "}
                          {ticket?.vendor}
                        </span>
                      </a>
                    )}
                    {ticket?.saleStartDate &&
                      !isBeforeStartDate(ticket.saleStartDate) &&
                      selectedDate && (
                        <span className="sale-start">
                          * Vendita biglietti dal {ticket.saleStartDate}
                        </span>
                      )}
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedDate(!selectedDate);
                        setFlightOffers([]);
                        setSelectedCity(null);
                        setSelectedNation(null);
                        selectedDate && setSelectedTicket(null);
                        scrollToElement("targetElement-0", 80)
                      }}
                      className={`ticket-link selected-date ${
                        selectedDate ? "selected-date-open" : ""
                      }`}
                    >
                      {selectedDate ? "Cambia data" : "Seleziona"}
                    </button>
                  </div>
                )}
              </div>
            ))}
        </div>
      )}

      {selectedTicket && selectedDate && (
        <>
          <span className="divider">Seleziona la nazione di partenza</span>

          <div className="nations" id="nations">
            {!selectedNation &&
              Object.entries(citiesMap.countries).map(([code, country]) => {
                const FlagComponent =
                  code === "IT"
                    ? Flags.ItalyFlag
                    : code === "UK"
                    ? Flags.UKFlag
                    : code === "DE"
                    ? Flags.GermanyFlag
                    : code === "ES"
                    ? Flags.SpainFlag
                    : code === "FR"
                    ? Flags.FranceFlag
                    : code === "NL"
                    ? Flags.NetherlandsFlag
                    : code === "PT"
                    ? Flags.PortugalFlag
                    : code === "CH"
                    ? Flags.SwitzerlandFlag
                    : code === "AT"
                    ? Flags.AustriaFlag
                    : code === "BE"
                    ? Flags.BelgiumFlag
                    : code === "PL"
                    ? Flags.PolandFlag
                    : code === "CZ"
                    ? Flags.CzechFlag
                    : code === "HU"
                    ? Flags.HungaryFlag
                    : code === "DK"
                    ? Flags.DenmarkFlag
                    : code === "SE"
                    ? Flags.SwedenFlag
                    : code === "NO"
                    ? Flags.NorwayFlag
                    : code === "FI"
                    ? Flags.FinlandFlag
                    : code === "GR"
                    ? Flags.GreeceFlag
                    : code === "IE"
                    ? Flags.IrelandFlag
                    : code === "HR"
                    ? Flags.CroatiaFlag
                    : code === "SI"
                    ? Flags.SloveniaFlag
                    : null;
                return (
                  <button
                    key={code}
                    onClick={() => {
                      setSelectedNation(code);
                      setQuery("");
                      setSelectedCity(null);
                      setFilteredCities([]);
                      setTimeout(() => {
                        inputRef.current?.focus();
                      }, 0);
                      scrollToElement("nations", 80)
                    }}
                    className="travel-departure nation"
                  >
                    {FlagComponent && <FlagComponent />}
                    <span>{country?.name}</span>
                  </button>
                );
              })}

            {selectedNation && (
              <>
                <button className="travel-departure selected-departure nation selected-nation">
                  {citiesMap.countries[selectedNation].name && (
                    <>
                      {(() => {
                        const FlagComponent =
                          selectedNation === "IT"
                            ? Flags.ItalyFlag
                            : selectedNation === "UK"
                            ? Flags.UKFlag
                            : selectedNation === "DE"
                            ? Flags.GermanyFlag
                            : selectedNation === "ES"
                            ? Flags.SpainFlag
                            : selectedNation === "FR"
                            ? Flags.FranceFlag
                            : selectedNation === "NL"
                            ? Flags.NetherlandsFlag
                            : selectedNation === "PT"
                            ? Flags.PortugalFlag
                            : selectedNation === "CH"
                            ? Flags.SwitzerlandFlag
                            : selectedNation === "AT"
                            ? Flags.AustriaFlag
                            : selectedNation === "BE"
                            ? Flags.BelgiumFlag
                            : selectedNation === "PL"
                            ? Flags.PolandFlag
                            : selectedNation === "CZ"
                            ? Flags.CzechFlag
                            : selectedNation === "HU"
                            ? Flags.HungaryFlag
                            : selectedNation === "DK"
                            ? Flags.DenmarkFlag
                            : selectedNation === "SE"
                            ? Flags.SwedenFlag
                            : selectedNation === "NO"
                            ? Flags.NorwayFlag
                            : selectedNation === "FI"
                            ? Flags.FinlandFlag
                            : selectedNation === "GR"
                            ? Flags.GreeceFlag
                            : selectedNation === "IE"
                            ? Flags.IrelandFlag
                            : selectedNation === "HR"
                            ? Flags.CroatiaFlag
                            : selectedNation === "SI"
                            ? Flags.SloveniaFlag
                            : null;
                        return FlagComponent && <FlagComponent />;
                      })()}
                      <span>{citiesMap.countries[selectedNation].name}</span>
                    </>
                  )}
                </button>

                <button
                  onClick={() => {
                    setSelectedNation(null);
                    setQuery("");
                    setSelectedCity(null);
                    setFilteredCities([]);
                    trackGAEvent("select_city", "City Selection", {
                      city_name: selectedCity?.name,
                      region: selectedCity?.region,
                      has_airport: selectedCity?.airports?.length > 0,
                      has_station: selectedCity?.stations?.length > 0,
                    });
                  }}
                  className="travel-departure nation change-nation"
                >
                  <span>Cambia</span>
                </button>
              </>
            )}
          </div>
        </>
      )}

      {selectedTicket && selectedDate && selectedNation && (
        <>
          <span className="divider">Inserisci la città di partenza</span>
          <input
            className="input-placeholder"
            type="text"
            placeholder="Città di partenza"
            onChange={(e) => setQuery(e.target.value)}
            value={query}
            ref={inputRef}
          />
        </>
      )}

      {filteredCities.length > 0 && !selectedCity && selectedDate && (
        <div className="travel-results">
          {filteredCities.map(
            (city, index) =>
              city?.name !== festivalCity?.name && (
                <div
                  className="travel-departure"
                  key={index}
                  onClick={() => {
                    setSelectedCity(city);
                    setQuery(city?.name);
                    if (city?.airports?.length > 0) fetchFlightOffers(city);
                  }}
                >
                  {city?.name} ({city?.region})
                </div>
              )
          )}
        </div>
      )}

      {selectedCity && (
        <div className="travel-results">
          <div
            className="travel-departure selected-departure"
            onClick={() => {
              setSelectedCity(null);
              setQuery("");
              setFlightOffers([]);
            }}
          >
            {selectedCity.name} ({selectedCity.region})
          </div>
        </div>
      )}

      {selectedCity && !loading && query === selectedCity?.name && (
        <span className="divider">Opzioni di viaggio</span>
      )}

      {loading && <Loader />}

      {!loading && selectedCity && selectedDate && (
        <div className="travel-results">
          <div className="trip-options">
            {selectedCity && !loading && query === selectedCity?.name && (
              <div className="trip-date-container">
                <span className="trip-date">{selectedTicket?.startDate}</span>
                <div className="trip-date-divider"></div>
                <span className="trip-date">
                  {format(
                    addDays(
                      parse(selectedTicket?.endDate, "dd/MM/yyyy", new Date()),
                      1
                    ),
                    "dd/MM/yyyy"
                  )}
                </span>
              </div>
            )}

            {selectedCity &&
              !loading &&
              selectedDate &&
              query === selectedCity?.name &&
              selectedCity?.region !== "Sicilia" &&
              selectedCity?.country === festivalCity?.country && (
                <TrainSearch
                  departure={{
                    name: selectedCity?.name,
                    code: selectedCity?.stations[0]?.code,
                    stationName: selectedCity?.stations[0]?.name,
                  }}
                  arrival={{
                    code: festivalCity?.stations[0]?.code,
                    name: festivalCity?.name,
                    stationName: festivalCity?.stations[0]?.name,
                    distanceFromFestival:
                      festivalCity?.stations[0]?.distanceFromFestival,
                  }}
                  date={format(
                    parse(selectedTicket?.startDate, "dd/MM/yyyy", new Date()),
                    "yyyy-MM-dd"
                  )}
                  returnDate={format(
                    addDays(
                      parse(selectedTicket?.endDate, "dd/MM/yyyy", new Date()),
                      1
                    ),
                    "yyyy-MM-dd"
                  )}
                />
              )}

            {!loading &&
              selectedDate &&
              selectedCity?.airports?.length > 0 &&
              festivalCity?.airports.length > 0 && (
                <div className="travel-offer" id="travel-offer">
                  <img
                    src={flightIcon}
                    alt="flight-icon"
                    className="flight-icon"
                  />
                  <div className="carriers-offer">
                    <span className="airport">
                      {festivalCity?.country === selectedCity?.country
                        ? selectedCity?.airports[0]?.name
                        : selectedCity?.airports[1]?.name
                        ? selectedCity?.airports[1]?.name
                        : selectedCity?.airports[0]?.name}
                    </span>
                    <span className="airport end">
                      {festivalCity?.country === selectedCity?.country
                        ? festivalCity?.airports[0]?.name
                        : festivalCity?.airports[1]?.name
                        ? festivalCity?.airports[1]?.name
                        : festivalCity?.airports[0]?.name}
                      {festivalCity?.airports[0]?.distanceFromFestival && (
                        <span className="distance-airport">
                          <br />*{" "}
                          {festivalCity?.airports[0]?.distanceFromFestival} da{" "}
                          {festivalCity?.name}
                        </span>
                      )}
                    </span>
                  </div>

                  <div className="carriers-offer-wrapper">
                    {flightOffers?.data?.length > 0 &&
                      Object.entries(flightOffers?.dictionaries?.carriers)
                        .map(([code, name]) => {
                          const offersForCarrier = flightOffers?.data.filter(
                            (offer) =>
                              offer.validatingAirlineCodes[0].includes(code)
                          );

                          const lowestPriceForCarrier =
                            offersForCarrier.length > 0
                              ? offersForCarrier.reduce((lowest, offer) => {
                                  const offerPrice = parseFloat(
                                    offer.price.grandTotal
                                  );
                                  return offerPrice < lowest
                                    ? offerPrice
                                    : lowest;
                                }, parseFloat(offersForCarrier[0].price.grandTotal))
                              : Infinity;

                          return {
                            code,
                            name,
                            lowestPrice: lowestPriceForCarrier,
                            hasOffers: offersForCarrier.length > 0,
                          };
                        })
                        .filter((carrier) => carrier.hasOffers)
                        .sort((a, b) => a.lowestPrice - b.lowestPrice)
                        .slice(0, 3)
                        .map(({ name, lowestPrice }) => (
                          <span className="carrier-name" key={name}>
                            {name} <br />
                            <span>{`${lowestPrice} €`}</span>
                          </span>
                        ))}
                  </div>

                  <div className="carriers-offer-wrapper">
                    <div
                      onClick={trackAffiliateClick("TripCom", "flight_search", {
                        festival_name: festival?.name,
                        departure_city: selectedCity?.name,
                        arrival_city: festivalCity?.name,
                        departure_date: festival?.startDate,
                        return_date: festival?.endDate,
                      })}
                      className="flight-search-button-wrapper"
                    >
                      <a
                        className="flight-search-button"
                        target="_blank"
                        rel="sponsored noopener noreferrer"
                        href={generateTripFlightUrl(
                          format(
                            parse(
                              selectedTicket?.startDate,
                              "dd/MM/yyyy",
                              new Date()
                            ),
                            "yyyy-MM-dd"
                          ),
                          format(
                            addDays(
                              parse(
                                selectedTicket?.endDate,
                                "dd/MM/yyyy",
                                new Date()
                              ),
                              1
                            ),
                            "yyyy-MM-dd"
                          )
                        )}
                        onClick={() =>
                          trackGAEvent("search_flights", "Travel Search", {
                            departure_city: selectedCity?.name,
                            arrival_city: festivalCity?.name,
                            departure_date: selectedTicket?.startDate,
                            return_date: selectedTicket?.endDate,
                          })
                        }
                      >
                        Prenota
                        <small style={{ color: "white" }}> su Trip</small>
                      </a>
                    </div>
                  </div>
                </div>
              )}
            {selectedCity &&
              selectedDate &&
              flightOffers &&
              !loading &&
              selectedDate &&
              flightOffers?.length === 0 && (
                <span>Nessuna offerta trovata per i voli</span>
              )}
            {selectedTicket?.omioLink &&
              selectedCity &&
              selectedDate &&
              flightOffers &&
              !loading &&
              selectedTicket?.omioLink?.length > 0 &&
              selectedTicket?.omioLink?.filter(
                (ticket) => selectedCity?.name === ticket?.from
              ).length > 0 && (
                <OmioTravel
                  departure={selectedCity?.name}
                  arrival={festivalCity?.name}
                  hasAirport={selectedCity?.airports?.length > 0}
                  link={
                    selectedTicket?.omioLink?.filter(
                      (ticket) => selectedCity?.name === ticket?.from
                    )[0]?.link
                  }
                />
              )}

            {selectedCity &&
              query === selectedCity?.name &&
              festival?.stay22Map &&
              !loading && (
                <>
                  <span className="divider">Opzioni di alloggio</span>
                  {selectedTicket?.type === "bundle" && (
                    <ExpediaHotels
                      link={selectedTicket?.expediaLink}
                      city={festival?.location}
                      checkIn={format(
                        parse(
                          selectedTicket?.startDate,
                          "dd/MM/yyyy",
                          new Date()
                        ),
                        "yyyy-MM-dd"
                      )}
                      checkOut={format(
                        addDays(
                          parse(
                            selectedTicket?.endDate,
                            "dd/MM/yyyy",
                            new Date()
                          ),
                          1
                        ),
                        "yyyy-MM-dd"
                      )}
                    />
                  )}

                  <div className="stay22-map">
                    <iframe
                      onLoad={() =>
                        trackGAEvent("view_stay22_map", "Accommodation", {
                          festival_name: festival.name,
                          city: festivalCity?.name,
                        })
                      }
                      title="stay22-map"
                      id="stay22-widget"
                      width="100%"
                      height="500px"
                      src={
                        selectedTicket?.stay22MapTicket?.length > 0
                          ? selectedTicket?.stay22MapTicket
                          : festival?.stay22Map
                      }
                      frameBorder="0"
                    ></iframe>
                  </div>

                  <BookingHotels
                    city={festivalCity?.name}
                    country={festivalCity?.country}
                    checkIn={format(
                      parse(
                        selectedTicket?.startDate,
                        "dd/MM/yyyy",
                        new Date()
                      ),
                      "yyyy-MM-dd"
                    )}
                    checkOut={format(
                      addDays(
                        parse(
                          selectedTicket?.endDate,
                          "dd/MM/yyyy",
                          new Date()
                        ),
                        1
                      ),
                      "yyyy-MM-dd"
                    )}
                    link={selectedTicket?.bookingLink}
                  />
                </>
              )}
          </div>
        </div>
      )}
    </>
  );
};

export default TravelSearch;
