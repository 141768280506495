import React from "react";
import { format, isValid, parse, differenceInCalendarDays } from "date-fns";
import { it } from "date-fns/locale";
import './expedia.style.css';

const ExpediaHotels = ({ city, link, checkIn, checkOut }) => {
  const formatDisplayDate = (dateStr1, dateStr2) => {
    if (!dateStr1 && !dateStr2) return "";
    const date1 = parse(dateStr1, "yyyy-MM-dd", new Date());
    const date2 = parse(dateStr2, "yyyy-MM-dd", new Date());
    if (!isValid(date1) && !isValid(date2)) return "";
    
    const month1 = format(date1, "MMMM", { locale: it });
    const month2 = format(date2, "MMMM", { locale: it });
    
    const dateString1 = month1 !== month2 ? format(date1, "d MMMM", { locale: it }) : format(date1, "d", { locale: it });
    const dateString2 = format(date2, "d MMMM", { locale: it });
    
    return `${dateString1} - ${dateString2}`;
  };

  const getDateDifference = (startDate, endDate) => {
    const parsedStartDate = parse(startDate, "yyyy-MM-dd", new Date());
    const parsedEndDate = parse(endDate, "yyyy-MM-dd", new Date());
    
    const diffInDays = parsedStartDate !== parsedEndDate ? differenceInCalendarDays(parsedEndDate, parsedStartDate) : 1;
    
    return diffInDays;
  };

  if (!link) return null;

  return (
    <div className="train-info">
      <div className="train-info-wrapper">
        <span className="font-medium">
          <span>
            Hotel vicini a <br /> {city} <br />
            {formatDisplayDate(checkIn, checkOut)}<br/>
          </span>
          <span>{getDateDifference(checkIn, checkOut)} {getDateDifference(checkIn, checkOut) > 1 ? 'notti' : 'notte'}</span>
        </span>
        <a
          className="train-search"
          href={link}
          target="_blank"
          rel="sponsored noopener noreferrer"
        >
          <span>Prenota <small className="text-white">su Expedia</small></span>
        </a>
      </div>
    </div>
  );
};

export default ExpediaHotels;