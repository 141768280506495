import React from 'react';

const ItalyFlag = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" width="20" height="15">
      <g fillRule="evenodd" strokeWidth="1pt">
        <path fill="#fff" d="M0 0h640v480H0z"/>
        <path fill="#009246" d="M0 0h213.3v480H0z"/>
        <path fill="#ce2b37" d="M426.7 0H640v480H426.7z"/>
      </g>
    </svg>
  );
  
const UKFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" width="20" height="15">
    <path fill="#012169" d="M0 0h640v480H0z"/>
    <path fill="#FFF" d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z"/>
    <path fill="#C8102E" d="m424 281 216 159v40L369 281h55zm-184 20 6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z"/>
    <path fill="#FFF" d="M241 0v480h160V0H241zM0 160v160h640V160H0z"/>
    <path fill="#C8102E" d="M0 193v96h640v-96H0zM273 0v480h96V0h-96z"/>
  </svg>
);
  
const GermanyFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" width="20" height="15">
    <path fill="#ffce00" d="M0 320h640v160H0z"/>
    <path d="M0 0h640v160H0z"/>
    <path fill="#dd0000" d="M0 160h640v160H0z"/>
  </svg>
);

const SpainFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" width="20" height="15">
    <path fill="#c60b1e" d="M0 0h640v480H0z"/>
    <path fill="#ffc400" d="M0 120h640v240H0z"/>
    <g transform="translate(200,240) scale(0.3)">
      <path fill="#c60b1e" d="M-20-10h40v20h-40z"/>
      <path fill="#c60b1e" d="M-10-20h20v40h-20z"/>
      <circle r="20" fill="#c60b1e"/>
      <circle r="10" fill="#ffc400"/>
    </g>
  </svg>
);

const FranceFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" width="20" height="15">
    <g fillRule="evenodd" strokeWidth="1pt">
      <path fill="#fff" d="M0 0h640v480H0z"/>
      <path fill="#00267f" d="M0 0h213.3v480H0z"/>
      <path fill="#f31830" d="M426.7 0H640v480H426.7z"/>
    </g>
  </svg>
);

const NetherlandsFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" width="20" height="15">
    <path fill="#21468B" d="M0 0h640v480H0z"/>
    <path fill="#FFFFFF" d="M0 160h640v160H0z"/>
    <path fill="#AE1C28" d="M0 0h640v160H0z"/>
  </svg>
);

const PortugalFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" width="20" height="15">
    <path fill="#FF0000" d="M256 0h384v480H256z"/>
    <path fill="#006600" d="M0 0h256v480H0z"/>
    <circle cx="256" cy="240" r="80" fill="#FFFF00"/>
    <path fill="#FF0000" d="M256 140c-32 0-96 48-96 80s64 80 96 80c32 0 96-48 96-80s-64-80-96-80z"/>
  </svg>
);

const SwitzerlandFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" width="20" height="15">
    <path fill="#FF0000" d="M0 0h640v480H0z"/>
    <path fill="#FFFFFF" d="M170 190h300v100H170z"/>
    <path fill="#FFFFFF" d="M270 90h100v300H270z"/>
  </svg>
);

const AustriaFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" width="20" height="15">
    <g fillRule="evenodd">
      <path fill="#fff" d="M640 480H0V0h640z"/>
      <path fill="#ed2939" d="M640 480H0V320h640zM640 160H0V0h640z"/>
    </g>
  </svg>
);

const BelgiumFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" width="20" height="15">
    <g fillRule="evenodd" strokeWidth="1pt">
      <path d="M0 0h213.3v480H0z" fill="#000001"/>
      <path d="M213.3 0h213.4v480H213.3z" fill="#ffd90c"/>
      <path d="M426.7 0H640v480H426.7z" fill="#f31830"/>
    </g>
  </svg>
);

const PolandFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" width="20" height="15">
    <g fillRule="evenodd">
      <path fill="#fff" d="M640 480H0V0h640z"/>
      <path fill="#dc143c" d="M640 480H0V240h640z"/>
    </g>
  </svg>
);

const CzechFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" width="20" height="15">
    <path fill="#fff" d="M0 0h640v480H0z"/>
    <path fill="#d7141a" d="M0 240h640v240H0z"/>
    <path fill="#11457e" d="M0 0l360 240L0 480V0z"/>
  </svg>
);

const HungaryFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" width="20" height="15">
    <g fillRule="evenodd">
      <path fill="#fff" d="M640 480H0V0h640z"/>
      <path fill="#388d00" d="M640 480H0V320h640z"/>
      <path fill="#d43516" d="M640 160H0V0h640z"/>
    </g>
  </svg>
);

const DenmarkFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" width="20" height="15">
    <path fill="#c60c30" d="M0 0h640v480H0z"/>
    <path fill="#fff" d="M205.714 0h68.571v480h-68.571z"/>
    <path fill="#fff" d="M0 205.714h640v68.571H0z"/>
  </svg>
);

const SwedenFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" width="20" height="15">
    <path fill="#006aa7" d="M0 0h640v480H0z"/>
    <path fill="#fecc00" d="M0 192h640v96H0z"/>
    <path fill="#fecc00" d="M176 0h96v480h-96z"/>
  </svg>
);

const NorwayFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" width="20" height="15">
    <path fill="#ed2939" d="M0 0h640v480H0z"/>
    <path fill="#fff" d="M180 0h120v480H180z"/>
    <path fill="#fff" d="M0 180h640v120H0z"/>
    <path fill="#002664" d="M210 0h60v480h-60z"/>
    <path fill="#002664" d="M0 210h640v60H0z"/>
  </svg>
);

const FinlandFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" width="20" height="15">
    <path fill="#fff" d="M0 0h640v480H0z"/>
    <path fill="#002f6c" d="M0 174.545h640v130.909H0z"/>
    <path fill="#002f6c" d="M175.455 0h130.909v480H175.455z"/>
  </svg>
);

const GreeceFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" width="20" height="15">
    <path fill="#0d5eaf" d="M0 0h640v480H0z"/>
    <path fill="#fff" d="M0 53.333h640v53.333H0zM0 160h640v53.333H0zM0 266.667h640V320H0zM0 373.333h640v53.333H0z"/>
    <path fill="#fff" d="M0 0h266.667v266.667H0z"/>
    <path fill="#0d5eaf" d="M106.667 0h53.333v266.667h-53.333z"/>
    <path fill="#0d5eaf" d="M0 106.667h266.667V160H0z"/>
  </svg>
);

const IrelandFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" width="20" height="15">
    <g fillRule="evenodd" strokeWidth="1pt">
      <path fill="#fff" d="M0 0h640v480H0z"/>
      <path fill="#009A49" d="M0 0h213.3v480H0z"/>
      <path fill="#FF7900" d="M426.7 0H640v480H426.7z"/>
    </g>
  </svg>
);

const CroatiaFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" width="20" height="15">
    <path fill="#fff" d="M0 0h640v480H0z"/>
    <path fill="#d80027" d="M0 160h640v160H0z"/>
    <path fill="#0039a6" d="M0 320h640v160H0z"/>
    <g transform="translate(320 240) scale(0.8)">
      <g fill="#d80027">
        <path d="M-50-40h100v80h-100z"/>
        <path d="M-40-50h80v100h-80z"/>
      </g>
    </g>
  </svg>
);

const SloveniaFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" width="20" height="15">
    <defs>
      <clipPath id="a">
        <path fillOpacity=".7" d="M-15 0h682.7v512H-15z"/>
      </clipPath>
    </defs>
    <g fillRule="evenodd" strokeWidth="1pt" clipPath="url(#a)" transform="translate(14.1) scale(.94)">
      <path fill="#fff" d="M-15 0h682.7v512H-15z"/>
      <path fill="#d72828" d="M-15 341.3h682.7V512H-15z"/>
      <path fill="#0038a8" d="M-15 170.7h682.7v170.6H-15z"/>
      <path fill="#fff" d="M233.6 170.7l-16.6 16.6 16.6 16.5-16.6 16.6 16.6 16.5-16.6 16.6 16.6 16.5-16.6 16.6 16.6 16.5-16.6 16.6 16.6 16.5-16.6 16.6h-33.2l-16.5-16.6 16.5-16.5-16.5-16.6 16.5-16.5-16.5-16.6 16.5-16.5-16.5-16.6 16.5-16.5-16.5-16.6 16.5-16.5-16.5-16.6h33.2l16.6-16.5"/>
    </g>
  </svg>
);

const Flags = {
  ItalyFlag,
  UKFlag,
  GermanyFlag,
  SpainFlag,
  FranceFlag,
  NetherlandsFlag,
  PortugalFlag,
  SwitzerlandFlag,
  AustriaFlag,
  BelgiumFlag,
  PolandFlag,
  CzechFlag,
  HungaryFlag,
  DenmarkFlag,
  SwedenFlag,
  NorwayFlag,
  FinlandFlag,
  GreeceFlag,
  IrelandFlag,
  CroatiaFlag,
  SloveniaFlag
};

export default Flags;