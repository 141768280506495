import React from "react";
import "./About.style.css";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <>
      <Helmet>
        <title>About Us | SoundTrip</title>
        <meta
          name="description"
          content="Scopri chi siamo e la nostra missione di connettere gli appassionati di musica con i migliori festival in Italia."
        />
        <link rel="canonical" href="https://soundtrip.online/about-us" />
      </Helmet>
      <div className="about-container">
        <h1 style={{ fontSize: "1.5rem" }}>
          Ehi Festival Lover!
        </h1>
        <h2 className="festival-title">Musica + Viaggi = Magia 🪄</h2>
        <p className="about-text">
SoundTrip è la piattaforma di riferimento per gli appassionati di festival musicali in Europa. Fondata nel 2024, la nostra missione è semplificare la scoperta, la pianificazione e la prenotazione di esperienze festival indimenticabili.

<h2 className="festival-title">La nostra visione</h2>

Crediamo nel potere della musica di unire le persone e arricchire le vite. I festival musicali non sono solo eventi, ma vere e proprie esperienze immersive che combinano musica, arte, cultura e connessioni umane. La nostra visione è rendere queste esperienze accessibili a tutti, fornendo una piattaforma completa e user-friendly che copre tutti gli aspetti del viaggio.

<h2 className="festival-title">Cosa offriamo</h2>

- Un ampio database di festival musicali in tutta Europa, con informazioni dettagliate su lineup, location, servizi e molto altro.<br/>
- Un potente motore di ricerca e filtri personalizzati per trovare il festival perfetto in base ai tuoi gusti musicali, budget e preferenze.<br/>
- Integrazione con partner di fiducia per prenotazioni di biglietti, alloggi e trasporti, il tutto in un unico posto.<br/>
- Matching personalizzato con festival basato sui tuoi ascolti Spotify, per scoprire nuovi eventi che si adattano perfettamente ai tuoi gusti musicali.<br/>
- Contenuti esclusivi e approfondimenti sui festival per immergerti completamente nell'esperienza festival.<br/>

<h2 className="festival-title">Contattaci</h2>

Siamo sempre felici di sentire i feedback e le idee dei nostri utenti. Se hai domande, suggerimenti o vuoi semplicemente dire ciao, non esitare a contattarci sui nostri canali social. 

Unisciti a noi in questo viaggio musicale e inizia a esplorare il mondo dei festival con SoundTrip!
        </p>
       
      </div>
    </>
  );
};

export default About;
