import React, { lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import "./Home.css";
import FestivalCard from "../../components/festival-card/festival-card";
import Search from "../../components/search/search";
import festivals from "../../assets/utils/festivals.json";
import WaveText from "../../components/wave-text/wave-text";

// Lazy loading dei componenti pesanti
const FestivalCalendar = lazy(() => import("../../components/festival-calendar/festival-calendar"));
const FestivalQuizGame = lazy(() => import("../../components/festival-quiz/festival-quiz"));
const Partners = lazy(() => import("../../components/partner/partner"));

// Configurazione SEO
const SEOConfig = {
  siteName: "SoundTrip",
  defaultKeywords: [
    'festival musicali europei',
    'viaggi concerti',
    'tour musicali',
    'festival estate europa',
    'eventi musicali live',
    'concerti internazionali',
    'turismo musicale'
  ]
};

const Home = () => {
  const { t } = useTranslation();
  
  // Metodo per ottenere le keywords
  const getKeywords = () => {
    const translatedKeywords = t('seo.primaryKeywords', null, { returnObjects: true });
    
    // Se le keywords tradotte esistono e sono un array, usale
    if (Array.isArray(translatedKeywords) && translatedKeywords.length > 0) {
      return translatedKeywords;
    }
    
    // Altrimenti, usa le keywords di default
    return SEOConfig.defaultKeywords;
  };

  const primaryKeywords = getKeywords();
  const keywordsString = Array.isArray(primaryKeywords) 
    ? primaryKeywords.join(', ') 
    : primaryKeywords;

  const pageTitle = t('seo.homeTitle', `Viaggi per Festival Musicali in Europa | ${SEOConfig.siteName} - Scopri, Prenota, Esplora`);
  const pageDescription = t('seo.homeDescription', 'SoundTrip: La tua guida definitiva per festival musicali in Europa. Organizza viaggi unici, scopri concerti incredibili e vivi esperienze musicali indimenticabili.');
  
  const canonicalUrl = typeof window !== 'undefined' 
    ? window.location.origin 
    : 'https://www.soundtrip.online';

  const renderFeaturedFestivals = () => (
    festivals
      .slice(0, 4)
      .map((festival, index) => (
        <FestivalCard key={festival.id || index} festival={festival} mini={false} />
      ))
  );

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content={keywordsString} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:site_name" content={SEOConfig.siteName} />
        <meta property="og:image" content="/path/to/logo.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content="/path/to/logo.jpg" />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>

      <div className="home-container">
        <h1 className="home-title">
          <WaveText text={t('home.title')}/>
        </h1>

        <div className="centered-text">
          <p className="home-subtitle">{t('home.subtitle')}</p>
          <p className="home-text-2">{t('home.searchTitle')}</p>

          <div className="festival-card-container">
            <Search />
          </div>
          
          <p className="home-text-2">{t('home.upcomingTitle')}</p>
          <div className="festival-card-container">
            {renderFeaturedFestivals()}
          </div>

          <Suspense fallback={<div>Loading...</div>}>
            <FestivalQuizGame festivals={festivals}/>
            <Partners/>
            <FestivalCalendar festivals={festivals} />
          </Suspense>
          
          <p className="home-text-2">{t('home.tagline')}</p>
        </div>
      </div>
    </>
  );
};

export default Home;