import React from "react";
import "./Explore.style.css";
import SpotifyAuthComponent from "../../components/spotify-login/spotify-login";
import Search from "../../components/search/search";
import { useTranslation } from "react-i18next";

const Explore = () => {
  const { t } = useTranslation();
  return (
    <div className="explore-container">
      <SpotifyAuthComponent />
          <p className="home-text-2">{t('home.searchTitle')}</p>
          <div className="festival-card-container">
            <Search />
          </div>
          
         

         
    </div>
  );
};

export default Explore;
