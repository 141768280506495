import React, { useEffect, useState } from "react";
import "./header.style.css";
import logo from "../../assets/Logo.png";
import logoMini from "../../assets/Logo-mini.png";
import instagramIcon from "../../assets/Instagram_icon.png";
import telegramIcon from '../../assets/telegram.svg';
import { useLocation } from "react-router-dom";

const Header = ({ minimize, festivalName }) => {
  const location = useLocation();
  const [active, setActive] = useState(location.pathname);
  const isFestivalPage = location.pathname.startsWith('/festival/');

  useEffect(() => {
    setActive(location.pathname);
    
    
  }, [location]);
  return (
    <>
      <div className={`header-mini-wrapper ${minimize ? 'visible' : ''}`}>
        <header className="header-mini-open">
          <img src={logoMini} alt="Logo" className="header-logo-mini" />
          <div className="header-row-menu-mini">
            
              <a href="/" className={`menu-link-white ${active === "/" ? "active" : ""}`}>
                HOME
              </a>
            
            
              <a href="/festival" className={`menu-link-white ${active === "/festival" ? "active" : ""}`}>
                FESTIVAL
              </a>
            
              <a href="/about-us" className={`menu-link-white ${active === "/about-us" ? "active" : ""}`}>
                ABOUT US
              </a>
            
          </div>
          
        </header>
        
        {isFestivalPage && minimize && (
          <div className="festival-name-bar">
            <span>{festivalName?.toUpperCase()}</span>
          </div>
        )}
      </div>

      <header className={`header ${minimize ? 'hidden' : ''}`}>
        <div className="header-column">
          <img src={logo} alt="Logo" className="header-logo" />
        </div>
        
        <div className="menu-wrapper">
          <div className="header-row-menu">
            <div>
              <a href="/" className={`menu-link ${active === "/" ? "active" : ""}`}>
                HOME
              </a>
            </div>
            <div>
              <a href="/festival" className={`menu-link ${active === "/festival" ? "active" : ""}`}>
                FESTIVAL
              </a>
            </div>
            <div>
              <a href="/about-us" className={`menu-link ${active === "/about-us" ? "active" : ""}`}>
                ABOUT US
              </a>
            </div>
          </div>
          
          <div className="icons-wrapper">
            <a href="https://www.instagram.com/soundtrip.online" target="_blank" rel="noreferrer">
              <img src={instagramIcon} alt="Logo" className="icon" />
            </a>
            <a href="https://t.me/+eks8npIY2lk4Nzk8" target="_blank" rel="noreferrer">
              <img src={telegramIcon} alt="Telegram" className="icon" />
            </a>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;