import React, { useState, useEffect, useCallback } from "react";
import SpotifyUserService from "../../services/spotifyUserService";
import SpotifyService from "../../services/spotify-service"; // Importiamo SpotifyService per i dettagli aggiuntivi
import "./spotifyAuth.css";
import spotifyIcon from "../../assets/spotify-icon.png";
import festivals from "../../assets/utils/festivals.json";
import { Link } from "react-router-dom";
import CircularLoader from "../circular-loader/circular-loader";

const SpotifyAuthComponent = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [topArtists, setTopArtists] = useState([]);
  const [artistInFestivals, setArtistInFestivals] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  // Stati per il modal
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [artistDetails, setArtistDetails] = useState(null);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [detailsError, setDetailsError] = useState(null);

  // Funzione per controllare quali artisti sono nei festival
  const checkArtistsInFestivals = useCallback((artists) => {
    const artistMatches = {};

    artists.forEach((artist) => {
      const matchingFestivals = [];

      festivals.forEach((festival) => {
        if (festival.lineup && Array.isArray(festival.lineup)) {
          // Controlla se l'artista è nella lineup principale del festival
          const isInLineup = festival.lineup.some(
            (lineupArtist) =>
              lineupArtist.toLowerCase() === artist.name.toLowerCase()
          );

          // Controlla anche nelle lineup dei singoli eventi del festival
          let isInTicketsLineup = false;
          if (festival.tickets && Array.isArray(festival.tickets)) {
            isInTicketsLineup = festival.tickets.some(
              (ticket) =>
                ticket.lineup &&
                Array.isArray(ticket.lineup) &&
                ticket.lineup.some(
                  (lineupArtist) =>
                    lineupArtist.toLowerCase() === artist.name.toLowerCase()
                )
            );
          }

          if (isInLineup || isInTicketsLineup) {
            matchingFestivals.push({
              name: festival.name,
              date: festival.startDate,
              location:
                festival.location && festival.location.length > 0
                  ? festival.location[0]
                  : "",
              country: festival.country,
              route:
                festival.route ||
                festival.name.toLowerCase().replace(/\s+/g, "-"),
            });
          }
        }
      });

      if (matchingFestivals.length > 0) {
        artistMatches[artist.id] = matchingFestivals;
      }
    });

    setArtistInFestivals(artistMatches);
    console.log("Artisti nei festival:", artistMatches);
  }, []);

  // Funzione per il check dell'autenticazione
  const checkAuthentication = useCallback(async () => {
    try {
      const authenticated = await SpotifyUserService.checkAuthentication();
      console.log(authenticated, "authenticated");

      if (authenticated) {
        const userData = await SpotifyUserService.getUserProfile();
        setUser(userData);
        console.log(userData);

        setIsAuthenticated(true);

        const artistsTop = await SpotifyUserService.getTopArtists();
        setTopArtists(artistsTop);

        // Controlla quali artisti sono presenti nei festival
        checkArtistsInFestivals(artistsTop);
      }
    } catch (err) {
      setError("Verifica autenticazione fallita");
      console.error("Errore di autenticazione:", err);
    } finally {
      // Imposta lo stato di caricamento a false indipendentemente dal risultato
      setIsLoading(false);
    }
  }, [checkArtistsInFestivals]);

  useEffect(() => {
    const handleAuthorizationCode = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");

      // Debug: Log per tracciare lo stato
      console.log("Codice di autorizzazione:", code);
      console.log("URL corrente:", window.location.href);

      if (code) {
        try {
          // Scambia il codice con i token
          await SpotifyUserService.handleCallback(code);

          // Recupera il profilo utente
          const userData = await SpotifyUserService.getUserProfile();

          // Recupera i top artisti
          const artistsTop = await SpotifyUserService.getTopArtists();

          // Aggiorna lo stato
          setUser(userData);
          setTopArtists(artistsTop);
          setIsAuthenticated(true);

          // Controlla quali artisti sono presenti nei festival
          checkArtistsInFestivals(artistsTop);

          // Rimuovi il codice dall'URL
          window.history.replaceState(
            {},
            document.title,
            window.location.pathname
          );
        } catch (err) {
          setError("Errore durante l'autenticazione");
          console.error("Errore di autenticazione:", err);
        } finally {
          // Imposta lo stato di caricamento a false quando il processo è completo
          setIsLoading(false);
        }
      } else {
        // Se non c'è un codice nell'URL, controlla l'autenticazione esistente
        checkAuthentication();
      }
    };

    handleAuthorizationCode();
  }, [checkAuthentication, checkArtistsInFestivals]);

  // Effetto per caricare i dettagli dell'artista quando viene selezionato
  useEffect(() => {
    if (selectedArtist) {
      const fetchArtistDetails = async () => {
        try {
          setIsLoadingDetails(true);
          setDetailsError(null);

          // Otteniamo informazioni aggiuntive sull'artista
          // se necessario possiamo chiamare altri endpoint come top tracks, ecc.
          const topTracks = await SpotifyService.getArtistTopTracks(
            selectedArtist.id
          );

          setArtistDetails({
            ...selectedArtist,
            topTracks: topTracks,
          });
        } catch (err) {
          setDetailsError("Impossibile caricare i dettagli dell'artista");
          console.error("Errore nel caricamento dei dettagli:", err);
        } finally {
          setIsLoadingDetails(false);
        }
      };

      fetchArtistDetails();
    }
  }, [selectedArtist]);

  const handleSpotifyLogin = async () => {
    try {
      const authResult = await SpotifyUserService.login();

      if (authResult) {
        setError(null);
        console.log(authResult, "authResult");
      }
    } catch (err) {
      setError("Accesso fallito. Riprova.");
      console.error("Errore login Spotify:", err);
    }
  };

  const handleLogout = async () => {
    try {
      await SpotifyUserService.logout();
      setIsAuthenticated(false);
      setUser(null);
      setTopArtists([]);
      setArtistInFestivals({});
    } catch (err) {
      setError("Logout fallito");
      console.error("Errore logout Spotify:", err);
    }
  };

  const handleArtistClick = (artist) => {
    setSelectedArtist(artist);
  };

  const handleCloseModal = () => {
    setSelectedArtist(null);
    setArtistDetails(null);
  };

  // Filtra solo gli artisti che sono presenti nei festival
  const artistsInLineup = topArtists.filter(
    (artist) => artistInFestivals[artist.id]
  );

  // Mostra un indicatore di caricamento mentre verifichiamo l'autenticazione
  if (isLoading) {
    return (
      <div className="spotify-auth-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  // Stili per il modal
  const modalStyles = {
    modal: {
      position: "fixed",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      backgroundColor: "rgba(0,0,0,0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
      transition: "all 0.3s",
    },
    modalContent: {
      backgroundColor: "white",
      padding: "2rem",
      borderRadius: "12px",
      color: "rgb(32, 39, 70)",
      width: "90%",
      maxWidth: "450px",
      maxHeight: "80vh",
      boxSizing: "border-box",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      overflowY: "auto",
      transition: "all 0.3s",
    },
    closeButton: {
      position: "absolute",
      top: "0.5rem",
      right: "1rem",
      border: "none",
      background: "none",
      fontSize: "24px",
      color: "rgb(32, 39, 70)",
      cursor: "pointer",
      padding: 0,
    },
    artistImage: {
      width: "180px",
      height: "180px",
      objectFit: "cover",
      borderRadius: "8px",
      margin: "0.5rem 0 0 0",
    },
    genreTag: {
      background: "#1DB954",
      color: "white",
      padding: "0.3rem 0.6rem",
      borderRadius: "30px",
      fontSize: "0.7rem",
      margin: "0.2rem",
      display: "inline-block",
    },
    genresContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      marginBottom: "1rem",
      maxWidth: "100%",
    },
    spotifyLink: {
      color: "white",
      textDecoration: "none",
      background: "#202746",
      display: "flex",
      alignItems: "center",
      padding: "0.5rem 1rem",
      borderRadius: "30px",
      gap: "0.5rem",
      margin: "1rem 0",
      fontWeight: "bold",
      transition: "all 0.2s",
      border: "2px solid #1db954",
      fontSize: "0.9rem",
    },
    festivalSection: {
      width: "100%",
      borderTop: "1px solid #eee",
      paddingTop: "1rem",
    },
    festivalTitle: {
      fontSize: "1rem",
      fontWeight: "bold",
      textAlign: "center",
      marginBottom: "1rem",
      color: "rgb(32, 39, 70)",
    },
    festivalItem: {
      display: "flex",
      alignItems: "center",
      padding: "0.75rem",
      borderRadius: "8px",
      marginBottom: "0.75rem",
      backgroundColor: "#202746",
      boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
      transition: "all 0.2s",
      textDecoration: "none",
      color: "white",
    },
    festivalInfo: {
      flex: "1",
    },
    festivalName: {
      margin: "0 0 0.3rem 0",
      fontSize: "0.9rem",
      fontWeight: "bold",
      color: "rgb(255, 166, 102)",
    },
    festivalDetails: {
      margin: "0",
      fontSize: "0.75rem",
      color: "white",
    },
    arrow: {
      fontSize: "1.2rem",
      color: "#ffa666",
    },
    loading: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "2rem",
      height: "200px",
    },
  };

  return (
    <div className="spotify-auth-container">
      {!isAuthenticated && (
        <div>
          <h2 className="spotify-auth-title">
            Scopri i Festival Perfetti Per Te
          </h2>
          <h5 style={{ color: "white" }}>
            Connetti Spotify e sblocca la tua esperienza musicale personalizzata
          </h5>
          <ul className="spotify-auth-features">
            <li className="spotify-feature">
              <span className="spotify-feature-title">
                <span style={{ fontSize: "1.2rem" }}>🎧 </span> Match con
                artisti
              </span>
              <span style={{ fontSize: "0.8rem" }}>
                Notifiche immediate quando i tuoi artisti ascoltati sono in
                lineup.
              </span>
            </li>
            <li className="spotify-feature">
              <span className="spotify-feature-title">
                <span style={{ fontSize: "1.2rem" }}>🎪 </span> Festival Su
                Misura
              </span>
              <span style={{ fontSize: "0.8rem" }}>
                Suggerimenti basati sulla tua libreria musicale.
              </span>
            </li>
            <li className="spotify-feature">
              <span className="spotify-feature-title">
                <span style={{ fontSize: "1.2rem" }}>🎫 </span> Ticket Alert
              </span>
              <span style={{ fontSize: "0.8rem" }}>
                Non perderti i festival con i tuoi artisti preferiti.
              </span>
            </li>
          </ul>
        </div>
      )}
      {error && <div className="spotify-error-message">{error}</div>}
      {!isAuthenticated ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
          }}
        >
          <button onClick={handleSpotifyLogin} className="spotify-login-button">
            <img
              src={spotifyIcon}
              alt="Spotify"
              className="spotify-icon-auth"
            />
            <span>ACCEDI CON SPOTIFY</span>
          </button>
          <span
            style={{ fontSize: "0.5rem", color: "white", marginTop: "0.5rem" }}
          >
            * La tua privacy è garantita, usiamo i dati solo per i match
            musicali.
          </span>
        </div>
      ) : (
        <div className="authenticated-user-info">
          {user && (
            <div className="user-profile-container">
              {user.images && user.images.length > 0 && (
                <img
                  src={user.images[0].url}
                  alt="Profilo Spotify"
                  className="user-profile-image"
                />
              )}

              <div className="user-profile-details">
                <p className="user-profile-name">{user.display_name}</p>
              </div>

              <button onClick={handleLogout} className="spotify-logout-button">
                Esci
              </button>
            </div>
          )}

          {/* Sezione Artisti in Lineup */}
          {artistsInLineup.length > 0 ? (
            <div className="top-artists-container">
              <h3 className="top-artists-title">I Tuoi Artisti in Lineup</h3>
              <div className="top-artists-grid">
                {artistsInLineup.map((artist) => (
                  <div
                    key={artist.id}
                    className="top-artist-card artist-in-festival"
                    title={`${artist.name} sarà presente in ${
                      artistInFestivals[artist.id].length
                    } festival!`}
                    onClick={() => handleArtistClick(artist)}
                  >
                    {artist.images && artist.images.length > 0 && (
                      <img
                        src={artist.images[0].url}
                        alt={artist.name}
                        className="top-artist-image"
                      />
                    )}
                    <p className="top-artist-name">{artist.name}</p>
                    <div className="artist-festivals-badge">
                      {artistInFestivals[artist.id].length}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="no-artists-message">
              <p>
                Nessuno dei tuoi artisti preferiti è in lineup nei prossimi
                festival.
              </p>
              <p>Continua ad esplorare per scoprire nuovi festival!</p>
            </div>
          )}
        </div>
      )}

      {/* Modal dell'artista */}
      {selectedArtist && (
        <div style={modalStyles.modal} onClick={handleCloseModal}>
          <div
            style={modalStyles.modalContent}
            onClick={(e) => e.stopPropagation()}
          >
            <button style={modalStyles.closeButton} onClick={handleCloseModal}>
              ×
            </button>

            {isLoadingDetails ? (
              <div style={modalStyles.loading}>
                <CircularLoader />
              </div>
            ) : detailsError ? (
              <div className="error-message">{detailsError}</div>
            ) : (
              artistDetails && (
                <>
                  <h2 style={{ margin: "0 0 0.5rem 0", textAlign: "center" }}>
                    {artistDetails.name}
                  </h2>

                  {artistDetails.images && artistDetails.images.length > 0 && (
                    <img
                      src={artistDetails.images[0].url}
                      alt={artistDetails.name}
                      style={modalStyles.artistImage}
                    />
                  )}

                  <a
                    href={artistDetails.external_urls?.spotify}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={modalStyles.spotifyLink}
                  >
                    <img
                      src={spotifyIcon}
                      alt="Spotify"
                      style={{ width: "20px", height: "20px" }}
                    />
                    Ascolta su Spotify
                  </a>

                  {/* Sezione Festival */}
                  {artistInFestivals[selectedArtist.id] &&
                    artistInFestivals[selectedArtist.id].length > 0 && (
                      <div style={modalStyles.festivalSection}>
                        <h3 style={modalStyles.festivalTitle}>
                          Festival in cui si esibirà nel 2025
                        </h3>

                        {artistInFestivals[selectedArtist.id].map(
                          (festival, index) => (
                            <Link
                              to={`/festival/${
                                festival.route ||
                                festival.name.toLowerCase().replace(/\s+/g, "-")
                              }`}
                              key={`${festival.name}-${index}`}
                              style={modalStyles.festivalItem}
                              onMouseOver={(e) => {
                                e.currentTarget.style.transform =
                                  "translateX(5px)";
                              }}
                              onMouseOut={(e) => {
                                e.currentTarget.style.transform = "none";
                              }}
                            >
                              <div style={modalStyles.festivalInfo}>
                                <h4 style={modalStyles.festivalName}>
                                  {festival.name}
                                </h4>
                                <p style={modalStyles.festivalDetails}>
                                  {festival.date && `${festival.date} • `}
                                  {festival.location &&
                                    ` ${festival.location} • `}
                                  {festival.country}
                                </p>
                              </div>
                              <div style={modalStyles.arrow}>›</div>
                            </Link>
                          )
                        )}
                      </div>
                    )}
                </>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SpotifyAuthComponent;
