import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./Festival.style.css";
import festivals from "../../assets/utils/festivals.json";
import linkIcon from "../../assets/website-icon.png";
import map from "../../assets/map.png";
import TravelSearch from "../../components/travel-search/travel-search";
import FestivalCountdown from "../../components/countdown/countdown";
import ShareButton from "../../components/share-button/share-button";
import VideoBackground from "../../components/video-background/video-background";
import FestivalServices from "../../components/services/services";

// Configurazione SEO
const SEOConfig = {
  siteName: "SoundTrip",
  getMetaTags: (festival) => ({
    title: `${festival?.name || "Festival"} 2025: Date, Biglietti, Viaggi | ${SEOConfig.siteName}`,
    description: `${festival?.name} ${festival?.year}: acquista i biglietti, consulta date, prezzi viaggio e line-up completa. ${festival?.lineup?.slice(0, 3).join(", ")} e altri artisti in concerto a ${festival?.city}.`,
    keywords: `${festival?.name}, festival ${festival?.year}, ${festival?.lineup?.join(", ")}, concerti ${festival?.city}, biglietti ${festival?.name}`,
  }),
  getOpenGraphTags: (festival, canonicalUrl) => ({
    type: "music.festival",
    url: canonicalUrl,
    title: `${festival?.name} ${festival?.year} | Line-up e Biglietti`,
    description: `${festival?.name} ${festival?.year} a ${festival?.city}. Line-up: ${festival?.lineup?.join(", ")}. Info, date e biglietti su ${SEOConfig.siteName}`,
    image: festival?.card,
  }),
  getTwitterTags: (festival) => ({
    card: "summary_large_image",
    title: `${festival?.name} ${festival?.year}`,
    description: `Line-up, date e biglietti per ${festival?.name} ${festival?.year} a ${festival?.city}`,
    image: festival?.card,
  }),
};

const Festival = () => {
  const { route } = useParams();
  const [festival, setFestival] = useState(null);
  const [isMapOpen, setIsMapOpen] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const canonicalUrl = `${baseUrl}/festival/${route}`;

  useEffect(() => {
    const fetchFestival = () => {
      const foundFestival = festivals.find(
        (f) => f?.name.replace(/\s+/g, "-").toLowerCase() === route
      );
      setFestival(foundFestival);
    };
    fetchFestival();
  }, [route]);

  const getStructuredData = () => ({
    "@context": "https://schema.org",
    "@type": "MusicEvent",
    name: festival.name,
    startDate: festival.startDate,
    endDate: festival.endDate,
    location: {
      "@type": "Place",
      name: festival.location[0],
      address: {
        "@type": "PostalAddress",
        addressLocality: festival.city,
        addressCountry: "IT",
      },
    },
    performer: festival.lineup?.map((artist) => ({
      "@type": "MusicGroup",
      name: artist,
    })),
    offers: festival.tickets?.map((ticket) => ({
      "@type": "Offer",
      price: ticket.minPrice,
      priceCurrency: "EUR",
      url: ticket.ticketLink,
      availability: "https://schema.org/InStock",
    })),
    image: festival.card,
    description: festival.info,
    eventStatus: "https://schema.org/EventScheduled",
    url: canonicalUrl,
  });

  const renderSpotifyPlayer = () => (
    festival?.playlistLink && (
      <div className="spotify-player-festival">
        <iframe
          title="spotify-playlist"
          style={{
            borderRadius: "10px",
            boxShadow: "1px 3px 3px rgba(0, 0, 0, 0.1)",
          }}
          src={festival.playlistLink}
          width="100%"
          height="352"
          frameBorder="0"
          allowFullScreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        />
      </div>
    )
  );

  const renderMap = () => (
    !isMapOpen ? (
      <div style={{ backgroundImage: `url(${map})` }} className="map">
        <div className="map-title">
          <span>{`${festival?.location[0]}, ${festival?.city}`}</span>
        </div>
        <div className="open-map-button-container">
          <button className="open-map-button" onClick={() => setIsMapOpen(true)}>
            Apri su <span className="site-link-button">Google Maps</span>
          </button>
        </div>
      </div>
    ) : (
      <div className="map-iframe">
        <iframe
          title="google-maps"
          src={festival?.src}
          width="100%"
          height="350"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>
    )
  );

  const { title, description, keywords } = SEOConfig.getMetaTags(festival);
  const ogTags = SEOConfig.getOpenGraphTags(festival, canonicalUrl);
  const twitterTags = SEOConfig.getTwitterTags(festival);

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />

        <meta property="og:type" content={ogTags.type} />
        <meta property="og:url" content={ogTags.url} />
        <meta property="og:title" content={ogTags.title} />
        <meta property="og:description" content={ogTags.description} />
        <meta property="og:image" content={ogTags.image} />

        <meta name="twitter:card" content={twitterTags.card} />
        <meta name="twitter:title" content={twitterTags.title} />
        <meta name="twitter:description" content={twitterTags.description} />
        <meta name="twitter:image" content={twitterTags.image} />

        <link rel="canonical" href={canonicalUrl} />

        {festival && (
          <script type="application/ld+json">
            {JSON.stringify(getStructuredData())}
          </script>
        )}
      </Helmet>

      <div className="festival-container">
        <div
          className="festival-name"
          style={{
            background: `url(${festival?.card}) no-repeat center center`,
            backgroundSize: "cover",
            backgroundBlendMode: "hard-light"
          }}
        >
          <span className="festival-name-text">{festival?.name}</span>
        </div>

        <FestivalCountdown startDate={festival?.startDate} />
        <VideoBackground youtubeUrl={festival?.youtubeUrl} />
        
        {festival?.services?.length > 0 && (
          <FestivalServices services={festival.services} />
        )}
        
        {!festival?.tickets && <span>Coming soon</span>}
        
        {festival && <TravelSearch festival={festival} />}
        
        {renderSpotifyPlayer()}

        <div className="menu">
          <div className="site-link">
            <img src={linkIcon} className="link-icon" alt="link" />
            <a
              rel="noreferrer"
              href={festival?.link}
              target="_blank"
              className="link"
            >
              <span className="festival-site">Visita il sito del festival</span>
            </a>
          </div>
          
          <ShareButton festival={festival} />
          {renderMap()}
        </div>
      </div>
    </>
  );
};

export default Festival;
