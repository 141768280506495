import React, { useState, useEffect } from "react";
import SpotifyService from "../../services/spotify-service";
import spotifyIcon from "../../assets/spotify-icon.png";
import CircularLoader from "../circular-loader/circular-loader";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    gap: "1rem",
  },
  lineupGrid: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    width: "100%",
    gap: "1rem",
    minHeight: "fit-content",
  },
  card: {
    width: "fit-content",
    backgroundColor: "white",
    borderRadius: "5px",
    whiteSpace: "nowrap",
    transition: "all 0.5s",
    overflow: "hidden",
    textTransform: "uppercase",
    textOverflow: "ellipsis",
    color: "rgb(32, 39, 70)",
    padding: "0.5rem",
    fontSize: "0.6rem",
    cursor: "pointer",
  },
  modal: {
    position: "fixed",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    backgroundColor: "rgba(0,0,0,0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
    transition: "all 1s 1s"
  },
  modalContent: {
    backgroundColor: "white",
    padding: "1rem",
    borderRadius: "8px",
    color: "rgb(32, 39, 70)",
    maxWidth: "80vw",
    boxSizing: "border-box",
    position: "relative",
    maxHeight: "70vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "310px",
    aspectRatio: "1/1",
    overflowY: "hidden",
    transition: "all 1s 1s"
  },
  closeButton: {
    position: "absolute",
    top: "0px",
    right: "0.7rem",
    border: "none",
    background: "none",
    fontSize: "36px",
    color: "rgb(32, 39, 70)",
    cursor: "pointer",
    padding: 0
  },
  artistImage: {
    width: "150px",
    height: "150px",
    objectFit: "cover",
    borderRadius: "4px"
  },
  spotifyIcon: {
    width: "20px",
    height: "20px",
    objectFit: "contain"
  },
  spotifyLink: {
    color: "white",
    textDecoration: "none",
    background: "rgb(32, 39, 70)",
    display: "flex",
    border: "2px solid #1DB954",
    padding: "0.5rem 1rem",
    borderRadius: "10px",
    gap: "1rem"
  },
  loading: {
    textAlign: "center",
    padding: "20px",
  },
  
  error: {
    color: "red",
    textAlign: "center",
    padding: "20px",
  },
  pagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "0.5rem",
    width: "100%",
    gap: "1rem"

  },
  paginationButton: {
    padding: "0.5rem 1rem",
    border: "1px solid rgb(32, 39, 70)",
    borderRadius: "4px",
    background: "white",
    color: "rgb(32, 39, 70)",
    cursor: "pointer",
    transition: "all 0.3s",
    fontSize: "0.9rem",
    "&:disabled": {
      opacity: 0.5,
      cursor: "not-allowed"
    },
    "&:hover:not(:disabled)": {
      backgroundColor: "rgb(32, 39, 70)",
      color: "white"
    }
  },
  pageIndicator: {
    color: "white",
    fontSize: "0.9rem",
    textAlign: "center"
  }
};

const ArtistCard = ({ artist, onClick }) => (
  <div
    style={styles.card}
    onClick={onClick}
    onMouseOver={(e) => {
      e.currentTarget.style.transform = "translateY(-2px)";
      e.currentTarget.style.boxShadow = "0 4px 8px rgba(0,0,0,0.1)";
    }}
    onMouseOut={(e) => {
      e.currentTarget.style.transform = "none";
      e.currentTarget.style.boxShadow = "0 2px 4px rgba(0,0,0,0.1)";
    }}
  >
    <h3 style={{ margin: 0 }}>{artist}</h3>
  </div>
);

const LineupComponent = ({ lineup }) => {
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [artistInfo, setArtistInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const totalPages = Math.ceil((lineup?.length || 0) / itemsPerPage);

  const getCurrentArtists = () => {
    const indexOfLastArtist = currentPage * itemsPerPage;
    const indexOfFirstArtist = indexOfLastArtist - itemsPerPage;
    return lineup?.slice(indexOfFirstArtist, indexOfLastArtist) || [];
  };

  useEffect(() => {
    if (selectedArtist) {
        console.log(selectedArtist, "selectedArtist");
        
      const fetchArtistData = async () => {
        try {
          setIsLoading(true);
          setError(null);
          const data = await SpotifyService.getCompleteArtistData(selectedArtist);
          setArtistInfo(data);
        } catch (err) {
          setError("Failed to load artist information");
          console.error(err);
        } finally {
          setIsLoading(false);
        }
      };

      fetchArtistData();
    }
  }, [selectedArtist]);

  const handleClose = () => {
    setSelectedArtist(null);
    setArtistInfo(null);
  };

  const handlePrevPage = () => {
    setCurrentPage(prev => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prev => Math.min(prev + 1, totalPages));
  };

  return (
    <div style={styles.container}>
      <div style={styles.lineupGrid}>
        {getCurrentArtists().map((artist, index) => (
          <ArtistCard
            key={`${artist}-${index}`}
            artist={artist}
            onClick={() => setSelectedArtist(artist)}
          />
        ))}
      </div>

      {lineup?.length> 7 && 
      <div style={styles.pagination}>
        <button 
          style={{
            ...styles.paginationButton,
            opacity: currentPage === 1 ? 0.5 : 1
          }}
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
          Prev
        </button>

        <span style={styles.pageIndicator}>
          {currentPage} / {totalPages}
        </span>

        <button 
          style={{
            ...styles.paginationButton,
            opacity: currentPage === totalPages ? 0.5 : 1
          }}
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
}

      {selectedArtist && (
        <div style={styles.modal} onClick={handleClose}>
          <div style={{...styles.modalContent,opacity: !isLoading && 1 }} onClick={(e) => e.stopPropagation()}>
            <button style={styles.closeButton} onClick={handleClose}>×</button>

            {isLoading && <div style={styles.loading}><CircularLoader /></div>}
            {error && <div style={styles.error}>{error}</div>}

            {artistInfo && (
              <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
                <p style={{maxWidth: "70%"}}>{artistInfo.name}</p>
                {artistInfo.imageUrl && (
                  <div style={{ position: "relative", width: "150px", margin: "0.5rem 0" }}>
                    <img
                      src={artistInfo.imageUrl}
                      alt={artistInfo.name}
                      style={styles.artistImage}
                    />
                    {artistInfo.genres.length > 0 && (
                      artistInfo.genres.map((genre, index) => (
                        index < 1 && 
                        <span key={genre} style={{
                          position: "absolute",
                          left: '0.5rem',
                          bottom: '0.5rem',
                          color: "white",
                          fontSize: "0.6rem",
                          background: "#1DB954",
                          padding: '0.2rem 0.3rem',
                          borderRadius: '2px'
                        }}>
                          {genre}
                        </span>
                      ))
                    )}
                  </div>
                )}

                <a
                  href={artistInfo.spotifyUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={styles.spotifyLink}
                >
                     <img 
                    src={spotifyIcon}
                    alt="Spotify" 
                    style={styles.spotifyIcon}
                  />
                  Listen on Spotify
                </a>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default LineupComponent;