// Servizio per la gestione dell'autenticazione Spotify
import axios from 'axios';

// Configurazione dell'applicazione Spotify
const SPOTIFY_CLIENT_ID = "6ed8204380e34ccf9fcff331969520e9";
const SPOTIFY_REDIRECT_URI = "https://soundtrip.online/festival";
const SPOTIFY_CLIENT_SECRET = "f92fa25254484a2caf5fd84ed78ff7d1"

// Ambiti (scopes) richiesti per l'autenticazione
const SPOTIFY_SCOPES = [
  'user-read-private',   // Leggere informazioni profilo di base
  'user-read-email',     // Leggere email dell'utente
  'user-top-read',       // Leggere i top artisti e brani
  'playlist-read-private' // Leggere playlist private
].join(' ');

// Classe di servizio per la gestione dell'autenticazione Spotify
class SpotifyUserService {
  // Genera l'URL di autorizzazione Spotify
  static getAuthorizationUrl() {
    const params = new URLSearchParams({
      client_id: SPOTIFY_CLIENT_ID || '',
      response_type: 'code',
      redirect_uri: SPOTIFY_REDIRECT_URI || '',
      scope: SPOTIFY_SCOPES,
      show_dialog: 'true'
    });

    return `https://accounts.spotify.com/authorize?${params.toString()}`;
  }

  // Verifica se l'utente è attualmente autenticato
  static async checkAuthentication() {
    const token = localStorage.getItem('spotify_access_token');
    if (!token) return false;

    try {
      // Convalida del token recuperando il profilo utente
      await this.getUserProfile();
      return true;
    } catch {
      // Token non valido
      this.clearTokens();
      return false;
    }
  }

  // Avvia il processo di login Spotify
  static async login() {
    // Reindirizza alla pagina di autorizzazione Spotify
    window.location.href = this.getAuthorizationUrl();
    return true;
  }

  // Gestisce il callback dopo l'autenticazione Spotify
  static async handleCallback(code) {
    try {
      // Scambio del codice di autorizzazione con i token di accesso
      const response = await axios.post('https://accounts.spotify.com/api/token', 
        new URLSearchParams({
          grant_type: 'authorization_code',
          code: code,
          redirect_uri: SPOTIFY_REDIRECT_URI || '',
          client_id: SPOTIFY_CLIENT_ID || '',
          client_secret: SPOTIFY_CLIENT_SECRET || ''
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );

      // Memorizzazione sicura dei token
      localStorage.setItem('spotify_access_token', response.data.access_token);
      localStorage.setItem('spotify_refresh_token', response.data.refresh_token);
      console.log(response?.data, "response");
      
    } catch (error) {
      console.error('Scambio token fallito', error);
      throw error;
    }
  }

  // Recupera il profilo utente da Spotify
  static async getUserProfile() {
    const accessToken = localStorage.getItem('spotify_access_token');
    
    if (!accessToken) {
      throw new Error('Nessun token di accesso disponibile');
    }

    try {
      const response = await axios.get('https://api.spotify.com/v1/me', {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });

      return response.data;
    } catch (error) {
      // Gestione scadenza o invalidità del token
      if (error.response && error.response.status === 401) {
        await this.refreshAccessToken();
        // Riprova a recuperare il profilo utente
        return this.getUserProfile();
      }
      throw error;
    }
  }

  // Aggiorna il token di accesso scaduto
  static async refreshAccessToken() {
    const refreshToken = localStorage.getItem('spotify_refresh_token');
    
    if (!refreshToken) {
      throw new Error('Nessun token di refresh disponibile');
    }

    try {
      const response = await axios.post('https://accounts.spotify.com/api/token', 
        new URLSearchParams({
          grant_type: 'refresh_token',
          refresh_token: refreshToken,
          client_id: SPOTIFY_CLIENT_ID || '',
          client_secret: SPOTIFY_CLIENT_SECRET || ''
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );

      // Aggiornamento token di accesso
      localStorage.setItem('spotify_access_token', response.data.access_token);
    } catch (error) {
      // Se l'aggiornamento fallisce, cancella i token e richiedi l'autenticazione
      this.clearTokens();
      throw error;
    }
  }

  // Recupera i top artisti dell'utente
  static async getTopArtists(limit = 50) {
    const accessToken = localStorage.getItem('spotify_access_token');
    
    if (!accessToken) {
      throw new Error('Nessun token di accesso disponibile');
    }

    try {
      const response = await axios.get(`https://api.spotify.com/v1/me/top/artists?limit=${limit}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });

      return response.data.items;
    } catch (error) {
      // Gestione errori come scadenza token
      if (error.response && error.response.status === 401) {
        await this.refreshAccessToken();
        return this.getTopArtists(limit);
      }
      throw error;
    }
  }

  // Logout e cancellazione dei token
  static async logout() {
    this.clearTokens();
  }

  // Metodo privato per cancellare i token memorizzati
  static clearTokens() {
    localStorage.removeItem('spotify_access_token');
    localStorage.removeItem('spotify_refresh_token');
  }
}

export default SpotifyUserService;